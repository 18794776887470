import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { getUsersForAdmin, UserForAdmin } from "services/ventureApi";
import { setAdminUsersData, setUsersForAdminData } from "features/admin/adminSlice";

export const useUsersForAdminData = () => {
  const dispatch = useDispatch();
  const adminUsersData = useSelector((state: RootState) => state.admin.usersForAdmin);

  const { data, isLoading, error, refetch } = useQuery<UserForAdmin[]>({
    queryKey: ["usersForAdminData"],
    queryFn: getUsersForAdmin,
    staleTime: 30 * 60 * 1000, // 30 minutes
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      console.log("DATA IN useUsersForAdminData", data);
      dispatch(setUsersForAdminData(data));
    }
  }, [data, dispatch]);

  return {
    adminUsersData,
    isAdminUsersLoading: isLoading,
    adminUsersError: error,
    refetchAdminUsers: refetch,
  };
};
