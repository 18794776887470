import { useState, useEffect, ReactNode } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import blank_profile from "assets/blank_profile.png";
import logo_placeholder from "assets/logo_placeholder.png";
import { retrieveMedia } from "services/ventureApi";
import { useAuth } from "context/AuthContext";

interface HeaderProps {
  tabValue: number;
  setTabValue: (value: number) => void;
  backgroundImage: string;
  entityName: string;
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ tabValue, setTabValue, entityName, children }) => {
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [logoImage, setLogoImage] = useState<string>(logo_placeholder);

  const { loginResponse } = useAuth();
  const entityId = loginResponse?.entity?.entity_id;

  const handleSetTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const fetchEntityImage = async () => {
    try {
      // Use the entityId to fetch the media
      const mediaResponse = await retrieveMedia("entity_logo");
      console.log("mediaResponse", mediaResponse);
      if (mediaResponse.presignedUrl) {
        setLogoImage(mediaResponse.presignedUrl);
      }
    } catch (error) {
      console.error("Error fetching entity image:", error);
    }
  };

  useEffect(() => {
    fetchEntityImage();
  }, [logoImage]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={logoImage || logo_placeholder}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {entityName}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab label="About" />
                {/*<Tab label="Messages" />
                <Tab label="Data Room" />
                */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
};

export default Header;
