import React from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

interface KpiCardProps {
  title: string;
  value: string | number;
  isLast?: boolean;
}

const KpiCard: React.FC<KpiCardProps> = ({ title, value, isLast }) => (
  <MDBox
    display="flex"
    flexDirection="column"
    alignItems="center"
    flex={1}
    p={1}
    sx={{ borderRight: isLast ? "none" : "1px solid #ccc" }}
  >
    <MDTypography variant="caption" textAlign="center" fontWeight="medium">
      {title}
    </MDTypography>
    <MDTypography variant="h5" textAlign="center" fontWeight="bold">
      {value}
    </MDTypography>
  </MDBox>
);

export default KpiCard;
