import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "app/store";
import { getEntityKpiStats } from "services/ventureApi";
import { setEntityKpiData } from "features/admin/adminSlice";

export const useEntitiesKpiData = () => {
  const dispatch = useDispatch();
  const { entityKpiStats } = useSelector((state: RootState) => state.admin);

  const {
    data: statsData,
    isLoading: isEntitiesKpiLoading,
    error: statsError,
    refetch: refetchEntityKpi,
  } = useQuery({
    queryKey: ["entityKpiStats"],
    queryFn: getEntityKpiStats,
    staleTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (statsData) {
      dispatch(setEntityKpiData(statsData));
    }
  }, [statsData, dispatch]);

  return {
    entityKpiStats,
    isEntitiesKpiLoading,
    statsError,
    refetchEntityKpi,
  };
};
