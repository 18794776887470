import React from "react";
import { useState } from "react";
import { Box, Card, CardContent, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import FormField from "layouts/pages/account/components/FormField";

interface AddAdminModalProps {
  onClose: () => void;
  itemName: string; // Name of the file or folder to delete
}

const AddAdminModal: React.FC<AddAdminModalProps> = ({ onClose, itemName }) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    role: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data: ", formValues);
  };

  return (
    <>
      {/* Backdrop for dimming background */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
          zIndex: 1000,
        }}
        onClick={onClose}
      />

      {/* Central Card */}
      <Card
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFFF",
          color: "white",
          width: "585px",
          height: "fit-content", // Increased height
          padding: "16px",
          zIndex: 1001,
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "8px",
        }}
      >
        {/* Card Content */}
        {/* Modal Header */}
        <MDBox
          style={{
            backgroundColor: "#344767",
            color: "white",
            borderRadius: "12px",
            height: "80px",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Spread content to edges
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            marginBottom: "16px",
            marginTop: "-50px",
          }}
        >
          {/* Center-aligned text */}
          <MDTypography
            fontSize="24px"
            fontWeight="bold"
            fontFamily="Roboto Slab"
            sx={{ color: "#FFFFFF", flex: 1, textAlign: "center" }}
          >
            Invite a team member
          </MDTypography>

          {/* Close Button aligned to the right */}
          <IconButton
            onClick={onClose}
            sx={{
              color: "white",
              fontSize: "16px",
              padding: 0,
            }}
          >
            <CloseIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </MDBox>

        {/* Modal Content */}
        <MDBox height="fit-content">
          <MDBox mt={3}>
            <CardContent sx={{ paddingBottom: 0 }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "400",
                  color: "#4C4E52",
                  fontFamily: "Roboto",
                }}
              >
                Please complete the information for the new Platform Administrator you want to add
              </Typography>
              <MDBox mb={2} width="80%">
                <FormField
                  type="text"
                  label="First Name"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: "#1C1C1C",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "#7B809A",
                      mt: "4px", // To move label closer to line
                    },
                  }}
                />
                <FormField
                  type="text"
                  label="Last Name"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: "#1C1C1C",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "#7B809A",
                      mt: "4px", // To move label closer to line
                    },
                  }}
                />
                <FormField
                  type="email"
                  label="Email Address"
                  name="emailAddress"
                  value={formValues.emailAddress}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: "#1C1C1C",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "#7B809A",
                      mt: "4px", // To move label closer to line
                    },
                  }}
                />
                <FormField
                  type="text"
                  label="Role in Company"
                  name="role"
                  value={formValues.role}
                  onChange={handleInputChange}
                  InputProps={{
                    sx: {
                      color: "#1C1C1C",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      color: "#7B809A",
                      mt: "4px", // To move label closer to line
                    },
                  }}
                />
              </MDBox>
            </CardContent>
            <Box display="flex" justifyContent="end">
              {/* Submit Button */}
              <Button
                variant="contained"
                // onClick={onDelete}
                sx={{
                  backgroundColor: "#202020", // White background for delete, grey for cancel
                  color: "#FFFFFF", // Delete text in dark color, cancel text in white
                  textTransform: "uppercase",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  padding: "12px 24px",
                  border: "none", // Remove the border entirely
                  outline: "none", // Remove the outline
                  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25);", // Remove any box-shadow (focus state or hover)
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "#202020", // Light grey for delete hover, darker grey for cancel hover
                    border: "none", // Ensure no border appears on hover
                    boxShadow: "none", // Remove any box-shadow during hover
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default AddAdminModal;
