import Card from "@mui/material/Card";
import { useState } from "react";
import { useDispatch } from "react-redux";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import DeleteConfirmationCard from "./DeleteConfirmationCard";

import { softDeleteUser } from "services/ventureApi";

function DeleteAccount(): JSX.Element {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // State to track the delete operation
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const response = await softDeleteUser(); // Call the service to soft delete the user

      if (response.success) {
        setShowDeleteModal(false); // Close the modal after successful deletion
      } else {
        console.log(response.message || "Failed to delete user account");
      }
    } catch (error) {
      console.log("An error occurred while deleting the account");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Card id="delete-account">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Delete Account</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            Once you delete your account, there is no going back. Please be certain.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDBox
            ml={{ xs: 0, sm: 1 }}
            mt={{ xs: 1, sm: 0 }}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <MDButton
              variant="gradient"
              color="error"
              sx={{ height: "100%" }}
              disabled={isDeleting} // Disable button during deletion process
            >
              {isDeleting ? "Deleting..." : "Delete Account"}{" "}
              {/* Change text based on isDeleting state */}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      {showDeleteModal && (
        <DeleteConfirmationCard onClose={handleModalClose} onDelete={handleDelete} />
      )}
    </Card>
  );
}

export default DeleteAccount;
