import { useDispatch } from "react-redux";
import { setLoading, setUserDetails, setError } from "features/user/updateProfileSlice";
import { updateUserDetails, UpdateUserRequest, UpdateUserResponse } from "services/ventureApi";
import { useState } from "react";

export const useUpdateUserDetails = () => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const updateUser = async (
    // userId: number,
    userData: UpdateUserRequest
  ): Promise<UpdateUserResponse> => {
    try {
      setIsUpdating(true);
      dispatch(setLoading(true));

      const response = await updateUserDetails(userData);

      if (response.success) {
        dispatch(setUserDetails(response.updatedUser || null));
      } else {
        dispatch(setError(response.message || "Failed to update user details"));
      }

      dispatch(setLoading(false));
      setIsUpdating(false);
      return response;
    } catch (error) {
      dispatch(setError("An error occurred while updating user details"));
      dispatch(setLoading(false));
      setIsUpdating(false);
      throw error;
    }
  };

  return { updateUser, isUpdating };
};
