// src/components/pdf/EntityPDFDocument.tsx
import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import pdfStyles from "styles/pdfStyles";

// Paths to your new assets
import frontCoverImage from "assets/images/reisiger/front page image.png";
import backCoverImage from "assets/images/reisiger/back page image.png";
import mainLogo from "assets/images/reisiger/logo2.png";
import smallLogoWatermark from "assets/images/reisiger/small logo.png";

import { AdminEntity } from "services/ventureApi"; // or wherever your type is
import MaturityDetails from "layouts/pages/business/business-questionaire/sections/maturity-details-1";

//const entityPlaceholder = "assets/images/reisiger/front page image.png";
const entityPlaceholder = "assets/logo_placeholder.png";
import { DetailedEntityResponse } from "types/adminTypes";

interface EntityPDFDocumentProps {
  entity: AdminEntity;
  data?: DetailedEntityResponse;
  userName?: string;
  // any additional props you want
}

const EntityPDFDocument: React.FC<EntityPDFDocumentProps> = ({
  entity,
  data,
  userName = "Unknown User",
}) => {
  // Helper to format date
  const entityType = entity.entity_type;
  const isInvestor = entity.entity_type === "Investor";
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  // Hardcode or derive from your data
  const dataDateRange = `${formattedDate} - ${formattedDate}`;

  // Example for date since "2024-07-02"
  const entitySinceDate = "2024-07-02"; // Hardcoded or from entity data
  // Example website
  const entityWebsite = entity.website || "reisiger.org";
  // Example address
  const entityAddressLines = ["1334 Cunningham Avenue", "Pretoria", "South Africa", "0186"];
  // Example one-liner
  const oneLiner =
    "Our investment approach focuses on technology primarily in Africa with a preference for pre-seed startups aiming to achieve a tech driven Africa.";

  return (
    <Document>
      {/* -----------------------------------------------------
          1) COVER PAGE (Shared for both Investor & Business)
          ----------------------------------------------------- */}
      <Page size="A4" style={pdfStyles.page}>
        <Image src={frontCoverImage} style={pdfStyles.coverImage} />
        <Image src={mainLogo} style={pdfStyles.coverLogo} />
        <Text style={pdfStyles.coverTitle}>{entity.entity_name}</Text>
        <Text style={pdfStyles.coverSubTitle}>Genesis Questionnaire Report</Text>

        <View style={pdfStyles.descriptionFields}>
          <Text style={pdfStyles.descriptionText}>DOWNLOADED BY: {userName}</Text>
          <Text style={pdfStyles.descriptionText}>DATE ISSUED: {formattedDate}</Text>
        </View>

        <View style={pdfStyles.coverFooter}>
          <Text>Agrigenesis</Text>
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>

      {/* -----------------------------------------------------
          2) CONDITIONAL CONTENT PAGES
          ----------------------------------------------------- */}
      {isInvestor ? (
        <InvestorPages entity={entity} data={data} />
      ) : (
        <BusinessPages entity={entity} data={data} />
      )}

      {/* -----------------------------------------------------
          3) BACK PAGE (Shared for both Investor & Business)
          ----------------------------------------------------- */}
      <Page size="A4" style={pdfStyles.backPage}>
        <Image src={backCoverImage} style={pdfStyles.backCoverImage} />
        <View style={pdfStyles.backContent}>
          <Image src={mainLogo} style={pdfStyles.backLogo} />
          <Text style={pdfStyles.backMotto}>
            Creating Economic Opportunities from Research and Innovation
          </Text>
        </View>
        <View style={pdfStyles.coverFooter}>
          <Text>Agrigenesis</Text>
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>
    </Document>
  );
};

export default EntityPDFDocument;

interface SubProps {
  entity: AdminEntity;
  data: DetailedEntityResponse;
}

const InvestorPages: React.FC<SubProps> = ({ entity, data }) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const dataDateRange = `${formattedDate} - ${formattedDate}`;

  // Example for date since "2024-07-02"
  // const entitySinceDate = "2024-07-02"; // Hardcoded or from entity data
  // Example website
  const entityWebsite = entity.website || "reisiger.org";
  // Example address
  //const entityAddressLines = ["1334 Cunningham Avenue", "Pretoria", "South Africa", "0186"];
  // Example one-liner
  const oneLiner =
    "Our investment approach focuses on technology primarily in Africa with a preference for pre-seed startups aiming to achieve a tech driven Africa.";
  //api data
  const { details, metadata, logo_url } = data || {};
  const {
    businessName,
    investorType,
    businessWebsite,
    establishmentDate,
    taxReferenceNumber,
    businessRegistrationNumber,
    primaryInvestmentSector,
  } = details || {};

  // Pull from oneLinerDetails
  const { oneLinerDetails, mandateDetails, investmentDetails, services, sdgDetails, fundingMech } =
    metadata || {};

  // For one-liner sentence
  const { geographicFocus, investmentStage, investmentImpact, targetIndustries } =
    oneLinerDetails || {};

  // Mandate fields
  const {
    stages,
    additionalInfo,
    industryDetails,
    ticketSizeMinimum,
    ticketSizeMaximum,
    ticketSizeTypical,
    ticketSizeCurrency,
    investmentGeographies,
    typicalInvestmentCurrency,
  } = mandateDetails || {};

  // Additional Info fields
  const {
    networksProvided,
    additionalPreferences,
    additionalRequirements,
    limitedPartnerStructure,
    additionalSupportProvided,
  } = additionalInfo || {};

  // Investment Details
  const {
    timeFrame,
    portfolioSize,
    funderRiskAppetite,
    portfolioValuation,
    portfolioValuationCurrency,
    collaborationPartners,
    equityInclusionCommitment,
  } = investmentDetails || {};

  const entityAddressLines = [
    details?.streetNo || "",
    details?.streetName || "",
    details?.suburb || "",
    details?.city || "",
    details?.country || "",
    details?.zipCode || "",
  ].filter(Boolean); // remove empty strings

  const oneLinerSentence = `We invest in ${targetIndustries || "technology"}, focusing on ${
    investmentStage || "early-stage"
  } in ${geographicFocus || "Africa"} to achieve ${investmentImpact || "sustainable growth"}`;

  const entitySinceDate = establishmentDate || "N/A";
  return (
    <>
      <Page size="A4" style={pdfStyles.page}>
        {/* Watermark (small logo), right-aligned, semi-transparent */}
        <Image src={smallLogoWatermark} style={pdfStyles.watermarkRightAligned} />
        <View style={pdfStyles.contentPage}>
          <Text style={pdfStyles.header}>{entity.entity_type || "Investor"} Profile Overview</Text>
          <View style={pdfStyles.line} />

          <View style={pdfStyles.photoLeftTextRightContainer}>
            <View style={pdfStyles.photoLeft}>
              <Image
                // your entity image or fallback - entity.entity_image_url ||
                src={logo_url || entityPlaceholder}
                style={pdfStyles.photo}
              />
            </View>
            <View style={pdfStyles.textRight}>
              {/* Entity name in bold, then 3 lines */}
              <Text style={pdfStyles.textBold}>{entity.entity_name}</Text>
              <Text style={pdfStyles.text}>Since {entitySinceDate}</Text>
              <Text style={pdfStyles.text}>{businessWebsite}</Text>

              {/* Address lines */}
              {entityAddressLines.map((line, idx) => (
                <Text key={idx} style={pdfStyles.text}>
                  {line}
                </Text>
              ))}
            </View>
          </View>

          {/* 2) Full-width, centered text one-liner 
          const oneLinerSentence = `We invest in ${targetIndustries || "technology"}, focusing on ${
    investmentStage || "early-stage"
  } in ${geographicFocus || "Africa"} to achieve ${investmentImpact || "sustainable growth"}`;
          */}
          <Text style={pdfStyles.centeredOneLiner}>
            We invest in <Text style={pdfStyles.textBold}>{targetIndustries || ""}</Text> , focusing
            on <Text style={pdfStyles.textBold}>{investmentStage}</Text> in{" "}
            <Text style={pdfStyles.textBold}>{geographicFocus}</Text> to achieve{" "}
            <Text style={pdfStyles.textBold}>{investmentImpact}</Text>
          </Text>

          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Company Basic Information</Text>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business registration Number:</Text>
            <Text style={pdfStyles.propValue}>{businessRegistrationNumber}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Tax reference Number:</Text>
            <Text style={pdfStyles.propValue}>{taxReferenceNumber || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Investor Type:</Text>
            <Text style={pdfStyles.propValue}>{investorType || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Primary Investment Sector:</Text>
            <Text style={pdfStyles.propValue}>{primaryInvestmentSector || "N/A"}</Text>
          </View>

          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Funding Mechanisms</Text>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Funding Mechanisms:</Text>
            {fundingMech && (
              <Text style={pdfStyles.propValue}>
                {Object.entries(fundingMech)
                  .map(([mechanismName, subArray]) => {
                    const joinedSubs = subArray.join(", ");
                    return `${mechanismName} (${joinedSubs})`;
                  })
                  .join(", ")}
              </Text>
            )}
          </View>
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Fund Maturity</Text>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Number of Startups in Current Portfolio:</Text>
            <Text style={pdfStyles.propValue}>{portfolioSize || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Current Portfolio Valuation:</Text>
            <Text style={pdfStyles.propValue}>
              {portfolioValuationCurrency || "N/A"} {portfolioValuation || "N/A"}
            </Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Funder Risk Appetite:</Text>
            <Text style={pdfStyles.propValue}>{funderRiskAppetite || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Engagement & Funding Allocation Timeframe:</Text>
            <Text style={pdfStyles.propValue}>{timeFrame || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Dedication to Equity, Diversity & Inclusion:</Text>
            <Text style={pdfStyles.propValue}>{equityInclusionCommitment || "N/A"}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Collaboration Partners:</Text>
            {collaborationPartners?.map((partner, idx) => (
              <Text key={idx} style={pdfStyles.propValue}>
                {partner},{" "}
              </Text>
            ))}
          </View>
        </View>

        {/* Footer */}
        <View style={pdfStyles.footer}>
          <Text>Agrigenesis</Text>
          <Text render={({ pageNumber }) => `Page ${pageNumber - 1}`} fixed />
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>

      {/* SECOND CONTENT PAGE */}
      <Page size="A4" style={pdfStyles.page}>
        <Image src={smallLogoWatermark} style={pdfStyles.watermarkRightAligned} />
        <View style={pdfStyles.contentPage}>
          {/* Title */}
          <Text style={pdfStyles.header}>Investor Mandate Overview</Text>
          <View style={pdfStyles.line} />

          {/* Section: Sustainable Development Goals */}
          <Text style={pdfStyles.subHeader}>Sustainable Development Goals</Text>
          {sdgDetails?.sdgDetails.map((goal, idx) => (
            <Text key={idx} style={pdfStyles.text}>
              {goal}
            </Text>
          ))}
          {/*      
          {sdgDetails &&
            Object.entries(sdgDetails).map(([sdgName, sdgValue], idx) => (
              <Text key={idx} style={pdfStyles.text}>
                {sdgName} (SDG #{sdgValue})
              </Text>
            ))}
*/}
          {/* Section: Industries */}
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Industries</Text>
          {mandateDetails?.industryDetails.map((industry, idx) => (
            <Text key={idx} style={pdfStyles.text}>
              {industry.industrySection}: {industry.industrySubclass}
            </Text>
          ))}

          {/* Section: Mandate Requirements */}
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Mandate Requirements</Text>
          {/* Example label-value rows */}
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Required Stage for Funding Eligibility:</Text>
            <Text style={pdfStyles.propValue}>Value for A</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Typical Ticket Size:</Text>
            <Text style={pdfStyles.propValue}>Value for B</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Maximum Ticket Size:</Text>
            <Text style={pdfStyles.propValue}>Value for B</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Minimum Ticket Size:</Text>
            <Text style={pdfStyles.propValue}>Value for B</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Investment Geographies:</Text>
          </View>

          {/* Example map at 50% width, centered */}
          <View style={{ alignItems: "center", marginVertical: 10 }}>
            <Text style={pdfStyles.textBold}>Investment Geography Map</Text>
            <Image
              // Replace with a rendered SVG or screenshot. For now, a placeholder or some map image
              src={entityPlaceholder}
              style={{ width: "50%", height: "auto", marginTop: 5 }}
            />
          </View>

          {/* Final section: Additional Requirements or Information */}
          <Text style={pdfStyles.subHeader}>Additional Requirements or Information</Text>

          <Text style={pdfStyles.textBold}>Resources or Networks Offered</Text>
          <Text style={pdfStyles.paragraph}>{networksProvided}</Text>

          <Text style={pdfStyles.textBold}>Additional Preferences</Text>
          <Text style={pdfStyles.paragraph}>{additionalPreferences}</Text>

          <Text style={pdfStyles.textBold}>Additional Requirements</Text>
          <Text style={pdfStyles.paragraph}>{additionalRequirements}</Text>

          <Text style={pdfStyles.textBold}>
            Fund Structure &amp; Relationships with Limited Partners
          </Text>
          <Text style={pdfStyles.paragraph}>{limitedPartnerStructure}</Text>
          <Text style={pdfStyles.textBold}>Additional Support Provided to Startups</Text>
          <Text style={pdfStyles.paragraph}>{additionalSupportProvided}</Text>
        </View>

        <View style={pdfStyles.footer}>
          <Text>Agrigenesis</Text>
          <Text render={({ pageNumber }) => `Page ${pageNumber - 1}`} fixed />
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>
    </>
  );
};

/* =======================
   BUSINESS PAGES COMPONENT
   ======================= */
const BusinessPages: React.FC<SubProps> = ({ entity, data }) => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const dataDateRange = `${formattedDate} - ${formattedDate}`;

  const entitySinceDate = "2024-02-02";
  // Example website
  const entityWebsite = entity.website || "agri.org";
  // Example address
  const entityAddressLines = ["2113 Cunningham Avenue", "Pretoria", "South Africa", "0188"];
  // Example one-liner
  const oneLiner =
    "Our venture approach focuses on technology primarily in Africa with a preference for small holdings aiming to achieve a agri-tech driven Africa.";

  const { metadata, name, registration_number, users, logo_url } = data || {};
  const {
    profile,
    oneLinerDetails,
    maturityDetails,
    entityDetails,
    financingDetails,
    founderDetails,
    industryDetails,
    marketDetails,
    needsDetails,
    //new_team_members,
    sdgDetails,
    services,
  } = metadata || {};
  const outerFounderDetails = founderDetails;
  const innerFounderDetails = outerFounderDetails.founderDetails;
  //oneliner
  const { businessName, problemOutline, keyOffering, targetMarket, valueProposition } =
    oneLinerDetails;
  //basic info

  const {
    registrationStatus,
    businessRegistrationNumber,
    taxReferenceNumber,
    businessType,
    primaryInvestmentSector,
  } = entityDetails || {};

  //founder details
  //const { founderDetails } = outerFounderDetails;

  //sdg
  //add array var to loop on
  //industryDetails
  // const { industryDetails } = industryDetails; //loop - industryClass + industrySubclass
  //page 2
  //maturity details
  const { businessMaturityStage, bbbeeLevel, riskAppetite, riskTolerance } = maturityDetails || {};
  const { scaleOfOperations, businessMarketType } = marketDetails || {};

  //problem maturity
  const problemMaturity = oneLinerDetails.problemMaturity;
  //finance

  const {
    recentFundingValuationCurrency,
    recentFundingValuation,
    totalFundingCurrency,
    totalFundingRaised,
    futureFundingRequired,
    futureFundingNeedCurrency,
    futureFundingNeed,
    futureFundingUse,
  } = financingDetails || {};

  return (
    <>
      {/* BUSINESS CONTENT PAGE 1 */}
      <Page size="A4" style={pdfStyles.page}>
        <Image src={smallLogoWatermark} style={pdfStyles.watermarkRightAligned} />
        <View style={pdfStyles.contentPage}>
          <Text style={pdfStyles.header}>Business Profile Overview</Text>
          <View style={pdfStyles.line} />
          <View style={pdfStyles.photoLeftTextRightContainer}>
            <View style={pdfStyles.photoLeft}>
              <Image
                //  entity image or fallback - entity.entity_image_url ||
                src={logo_url || entityPlaceholder}
                style={pdfStyles.photo}
              />
            </View>
            <View style={pdfStyles.textRight}>
              {/* Entity name in bold, then 3 detail lines */}
              <Text style={pdfStyles.textBold}>{entity.entity_name}</Text>
              <Text style={pdfStyles.text}>Since {entitySinceDate}</Text>
              <Text style={pdfStyles.text}>{entityWebsite}</Text>

              {/* Address lines */}
              {entityAddressLines.map((line, idx) => (
                <Text key={idx} style={pdfStyles.text}>
                  {line}
                </Text>
              ))}
            </View>
          </View>
          {/* 2) oneliner*/}
          <Text style={pdfStyles.centeredOneLiner}>
            <Text style={pdfStyles.textBold}>{businessName}</Text> addresses{" "}
            <Text style={pdfStyles.textBold}>{problemOutline}</Text> through{" "}
            <Text style={pdfStyles.textBold}>{keyOffering}</Text> that enables{" "}
            <Text style={pdfStyles.textBold}>{targetMarket}</Text> to{" "}
            <Text style={pdfStyles.textBold}>{valueProposition}</Text>.
          </Text>

          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Company Basic Information</Text>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Registration Status:</Text>
            <Text style={pdfStyles.propValue}>{registrationStatus}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Registration Number:</Text>
            <Text style={pdfStyles.propValue}>{businessRegistrationNumber}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Tax Reference Number:</Text>
            <Text style={pdfStyles.propValue}>{taxReferenceNumber}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Type:</Text>
            <Text style={pdfStyles.propValue}>{businessType}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Primary Investment Sector:</Text>
            <Text style={pdfStyles.propValue}>{primaryInvestmentSector}</Text>
          </View>
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Founders</Text>
          {founderDetails?.founderDetails.map((founder, idx) => {
            return (
              <View key={idx} style={{ marginBottom: 8 }}>
                <Text style={pdfStyles.textBold}>
                  {founder.founderName} {founder.founderSurname || "no data found"}
                </Text>

                <View style={pdfStyles.propRow}>
                  <Text style={pdfStyles.propLabel}>Date of Birth:</Text>
                  <Text style={pdfStyles.propValue}>{founder.birthDate || "no data found"}</Text>
                </View>

                <View style={pdfStyles.propRow}>
                  <Text style={pdfStyles.propLabel}>Gender:</Text>
                  <Text style={pdfStyles.propValue}>{founder.gender || "no data found"}</Text>
                </View>

                <View style={pdfStyles.propRow}>
                  <Text style={pdfStyles.propLabel}>Ethnicity:</Text>
                  <Text style={pdfStyles.propValue}>{founder.ethnicity || "no data found"}</Text>
                </View>

                <View style={pdfStyles.propRow}>
                  <Text style={pdfStyles.propLabel}>Entrepreneur Type:</Text>
                  <Text style={pdfStyles.propValue}>
                    {founder.entrepreneurType || "no data found"}
                  </Text>
                </View>

                <View style={pdfStyles.propRow}>
                  <Text style={pdfStyles.propLabel}>Experience:</Text>
                  <Text style={pdfStyles.propValue}>
                    {founder.entrepreneurExperience || "no data found"}
                  </Text>
                </View>
              </View>
            );
          })}

          <Text style={pdfStyles.subHeader}>Sustainable Development Goals</Text>

          {sdgDetails?.sdgDetails.map((goal, idx) => (
            <Text key={idx} style={pdfStyles.text}>
              {goal}
            </Text>
          ))}
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Industries</Text>

          {industryDetails?.industryDetails.map((industry, idx) => (
            <Text key={idx} style={pdfStyles.text}>
              {industry.industrySection}: {industry.industrySubclass}
            </Text>
          ))}
        </View>

        <View style={pdfStyles.footer}>
          <Text>Agrigenesis</Text>
          <Text render={({ pageNumber }) => `Page ${pageNumber - 1}`} fixed />
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>

      {/* BUSINESS CONTENT PAGE 2 */}
      <Page size="A4" style={pdfStyles.page}>
        <Image src={smallLogoWatermark} style={pdfStyles.watermarkRightAligned} />
        <View style={pdfStyles.contentPage}>
          <Text style={pdfStyles.header}>Business Development and Market</Text>
          <View style={pdfStyles.line} />

          <Text style={pdfStyles.subHeader}>Business Development & Risk</Text>

          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Development Stage:</Text>
            <Text style={pdfStyles.propValue}>{businessMaturityStage}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>BBBEE Level:</Text>
            <Text style={pdfStyles.propValue}>{bbbeeLevel}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Risk Appetite:</Text>
            <Text style={pdfStyles.propValue}>{riskAppetite}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Risk Tolerance:</Text>
            <Text style={pdfStyles.propValue}>{riskTolerance}</Text>
          </View>
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Problem Maturity & Market Size</Text>

          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Problem Maturity:</Text>
            <Text style={pdfStyles.propValue}>{problemMaturity}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Scale of Business Operations:</Text>
            <Text style={pdfStyles.propValue}>{scaleOfOperations}</Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Business Market Type:</Text>
            <Text style={pdfStyles.propValue}>{businessMarketType}</Text>
          </View>
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.header}>Finance</Text>
          <View style={pdfStyles.line} />

          <Text style={pdfStyles.subHeader}>Funding Overview</Text>

          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Latest Funding Valuation:</Text>
            <Text style={pdfStyles.propValue}>
              {recentFundingValuationCurrency} {recentFundingValuation}
            </Text>
          </View>
          <View style={pdfStyles.propRow}>
            <Text style={pdfStyles.propLabel}>Total Funds Raised to Date:</Text>
            <Text style={pdfStyles.propValue}>
              {totalFundingCurrency} {totalFundingRaised}
            </Text>
          </View>
          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.textBold}>Future Funding Required</Text>

          <Text style={pdfStyles.text}>{futureFundingRequired}</Text>
          <Text style={pdfStyles.text}>
            {futureFundingNeedCurrency} {futureFundingNeed}
          </Text>
          <Text style={pdfStyles.text}>{futureFundingUse}</Text>

          <View style={pdfStyles.divider}></View>
          <Text style={pdfStyles.subHeader}>Funding History</Text>
          {Object.entries(financingDetails?.fundingBreakdown ?? {}).map(
            ([roundName, roundData], idx) => {
              let formattedDate = "N/A";
              if (roundData.date) {
                const dateObj = new Date(roundData.date);
                formattedDate = dateObj.toISOString().split("T")[0];
              }
              return (
                <View key={idx} style={{ marginBottom: 8 }}>
                  <Text style={pdfStyles.textBold}>{roundName}</Text>
                  <Text style={pdfStyles.text}>{formattedDate}</Text>
                  <Text style={pdfStyles.text}>
                    {roundData.currency} {roundData.fundingAmount}
                  </Text>
                  <View style={pdfStyles.propRow}>
                    <Text style={pdfStyles.propLabel}>Funding Use:</Text>
                    <Text style={pdfStyles.propValue}>{roundData.fundingUse}</Text>
                  </View>
                </View>
              );
            }
          )}
        </View>
        <View style={pdfStyles.footer}>
          <Text>Agrigenesis</Text>
          <Text render={({ pageNumber }) => `Page ${pageNumber - 1}`} fixed />
          <Text>All rights reserved ©2025 AgriGenesis</Text>
        </View>
      </Page>
    </>
  );
};
