import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define initial state
interface UserState {
  user: {
    user_id: number;
    first_name: string;
    last_name: string;
    phone_number: string;
    location: string;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

// Create a slice
const updatedProfileSlice = createSlice({
  name: "updateUserProfile",
  initialState,
  reducers: {
    setUserDetails(state, action: PayloadAction<UserState["user"]>) {
      state.user = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setUserDetails, setLoading, setError } = updatedProfileSlice.actions;

export default updatedProfileSlice.reducer;
