import Card from "@mui/material/Card";
import { useState } from "react";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";

import DeleteConfirmationCard from "./DeleteConfirmationCard";

function DeleteAccount(): JSX.Element {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };
  return (
    <Card id="delete-account">
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Delete Account</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            Once you delete your account, there is no going back. Please be certain.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDBox
            ml={{ xs: 0, sm: 1 }}
            mt={{ xs: 1, sm: 0 }}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <MDButton variant="gradient" color="error" sx={{ height: "100%" }}>
              delete account
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      {showDeleteModal && <DeleteConfirmationCard onClose={handleModalClose} onDelete={null} />}
    </Card>
  );
}

export default DeleteAccount;
