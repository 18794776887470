import { StyleSheet } from "@react-pdf/renderer";

const pdfStyles = StyleSheet.create({
  page: {
    position: "relative",
    backgroundColor: "#FFFFFF",
  },
  chartImageM: {
    width: "100%",
    height: "auto",
    marginBottom: 10,
  },
  coverImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "80%",
    width: "100%",
  },
  coverLogo: {
    position: "absolute",
    top: 20,
    right: 20,
    width: 150,
    height: "auto",
  },
  coverTitle: {
    position: "absolute",
    top: "70%",
    left: 40,
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff",
  },
  coverSubTitle: {
    position: "absolute",
    top: "75%",
    left: 40,
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff",
  },
  coverText: {
    position: "absolute",
    top: "50%",
    left: 40,
    right: 40,
    fontSize: 12,
    textAlign: "left",
    width: "50%",
    color: "#fff",
  },
  descriptionFields: {
    position: "absolute",
    bottom: 75,
    left: 40,
    right: 40,
    fontSize: 12,
    textAlign: "left",
  },
  descriptionText: {
    marginBottom: 5,
  },
  coverFooter: {
    position: "absolute",
    bottom: 20,
    left: 40,
    right: 40,
    fontSize: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    color: "grey",
  },
  contentPage: {
    position: "relative",
    paddingTop: 50,
    paddingHorizontal: 36,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subHeader: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  divider: {
    marginTop: 10,
  },
  pageTitle: {
    position: "absolute",
    top: "10%",
    left: 40,
    fontSize: 24,
    fontWeight: "bold",
    color: "#000000",
  },
  line: {
    position: "relative",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    marginBottom: 10,
  },
  mapComponentImage: {
    width: "100%",
    height: "auto",
    marginBottom: 20,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 40,
    right: 40,
    fontSize: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    color: "grey",
  },
  watermark: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 1,
  },
  watermarkRightAligned: {
    position: "absolute",
    top: 40,
    right: 0,
    width: 500,
    height: "90%",
    opacity: 0.75,
  },
  backPage: {
    position: "relative",
    backgroundColor: "#FFFFFF",
  },
  backCoverImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  backContent: {
    position: "absolute",
    top: 600,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 40,
  },
  backLogo: {
    width: 250,
    height: "auto",
    //marginBottom: 250,
    //top: 200,
    //left: 0,
    //right: 0,
    //bottom: 200,
  },
  backMotto: {
    fontSize: 24,
    fontStyle: "italic",
    color: "#fff",
    textAlign: "center",
    marginBottom: 65,
    paddingLeft: 50,
    paddingRight: 50,
  },
  text: {
    fontSize: 10,
    marginBottom: 2,
  },
  textBold: {
    fontSize: 10,
    marginBottom: 2,
    fontWeight: 700,
  },
  //
  // --------------------------------
  // New text styles
  // --------------------------------
  paragraph: {
    fontSize: 10,
    textAlign: "justify",
    marginBottom: 8,
    lineHeight: 1.4,
  },
  bulletList: {
    marginVertical: 4,
    marginLeft: 18,
  },
  bulletItem: {
    fontSize: 10,
    marginBottom: 4,
    textAlign: "justify",
    lineHeight: 1.4,
  },
  bold: {
    fontWeight: "bold",
  },

  // For property rows: (bold property: normal value)
  propRow: {
    flexDirection: "row",
    marginBottom: 4,
  },
  propLabel: {
    fontSize: 10,
    fontWeight: "bold",
    width: "auto", // or flexible
  },
  propValue: {
    fontSize: 10,
    marginLeft: 2,
    flexShrink: 1,
  },

  // --------------------------------
  // "sectionH": chart left, text right
  // --------------------------------
  sectionHContainer: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 12,
  },
  sectionHChart: {
    width: "60%", // chart ~60%
    marginRight: 10,
  },
  sectionHRHS: {
    width: "40%",
  },
  sectionHHeader: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 4,
  },
  sectionHText: {
    fontSize: 10,
    textAlign: "justify",
    lineHeight: 1.4,
  },

  // --------------------------------
  // "sectionV": chart big (75%) centered, text below
  // --------------------------------
  sectionVContainer: {
    width: "100%",
    marginBottom: 14,
    //alignItems: "center",
  },
  sectionVHeader: {
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 6,
  },
  sectionVPropRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 4,
  },
  sectionVPropLabel: {
    fontSize: 10,
    fontWeight: "bold",
    marginRight: 4,
  },
  sectionVPropValue: {
    fontSize: 10,
  },
  sectionVChart: {
    width: "65%",
    marginVertical: 3,
    alignSelf: "center",
  },
  sectionVParagraph: {
    fontSize: 10,
    textAlign: "justify",
    lineHeight: 1.4,
    marginTop: 3,
  },
  //new section
  fullWidthCaptionedContainer: {
    marginBottom: 20,
  },
  fullWidthImageBody: {
    fontSize: 10,
    textAlign: "justify",
    marginBottom: 8,
    lineHeight: 1.4,
  },
  fullWidthImageWrapper: {
    position: "relative",
    width: "100%",
    marginBottom: 8,
  },
  fullWidthImage: {
    width: "100%",
    height: "auto",
  },
  imageCaptionOverlay: {
    position: "absolute",
    left: 0,
    bottom: 4,
    right: 0,
    paddingHorizontal: 5,
  },
  imageCaption: {
    fontSize: 8,
    textAlign: "justify",
    lineHeight: 1.2,
  },
  imageCaptionTitle: {
    fontWeight: "bold",
  },
  //content
  photoLeftTextRightContainer: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 12,
  },
  photoLeft: {
    width: "30%", // or 25%, etc.
    marginRight: 10,
  },
  photo: {
    width: "100%",
    height: "auto",
  },
  textRight: {
    width: "70%",
  },
  centeredCaption: {
    textAlign: "center",
    fontSize: 10,
    marginVertical: 8,
    lineHeight: 1.4,
  },
  centeredOneLiner: {
    textAlign: "center",
    fontSize: 10,
    marginVertical: 8,
    lineHeight: 1.4,
    marginHorizontal: 40, // This constrains the text width
  },
});

export default pdfStyles;
