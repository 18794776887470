import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import FormField from "../../components/FormField";
import MDButton from "components/generic/MDButton";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { useAuth } from "context/AuthContext";
import { useUpdateUserDetails } from "hooks/userProfile/useUpdateUserDetails";
import { useUserProfile } from "hooks/userProfile/useUserProfile";

const BasicInfo: React.FC = () => {
  const { loginResponse } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    location: "",
    phoneNumber: "",
  });

  const [inputValue, setInputValue] = useState<string>("");
  const [activeUserId, setActiveUserId] = useState<number>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { updateUser, isUpdating } = useUpdateUserDetails();
  const { profile, loading, error } = useSelector((state: RootState) => state.user);
  const { isFetching } = useUserProfile();

  useEffect(() => {
    console.log(profile?.email, loginResponse.user_profile.email);
    if (profile?.email === loginResponse.user_profile.email) {
      setActiveUserId(loginResponse.user_profile.user_id);
    }
  }, [profile]);

  // Function to handle the change event and send PUT request
  const handleChange = async (field: string, value: string | string[]) => {
    // Update form data locally
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    if (!activeUserId) {
      console.warn("No active user ID available.");
      return;
    }

    try {
      const userData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        phone_number: formData.phoneNumber,
        location: formData.location,
      };
      await updateUser(userData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (profile) {
      // Reset form fields to current user profile values
      setFormData({
        firstName: profile.first_name || "",
        lastName: profile.last_name || "",
        email: profile.email || "",
        location: profile.location || "",
        phoneNumber: profile.phone_number || "",
      });
    }
  };

  useEffect(() => {
    console.log("Settings loginResponse", loginResponse);
    if (profile?.email !== loginResponse.user_profile.email) {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        location: "",
        phoneNumber: "",
      });
    } else {
      setFormData({
        firstName: profile.first_name || "",
        lastName: profile.last_name || "",
        email: profile.email || "",
        location: profile.location || "",
        phoneNumber: profile.phone_number || "",
      });
    }
  }, [profile]);

  // const handleChange = (field: string, value: string | string[]) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [field]: value,
  //   }));
  // };

  const formFieldStyles = {
    sx: {
      backgroundColor: "white !important",
      "& .MuiInputBase-root": {
        backgroundColor: "white !important",
        color: isEditing ? "#7B809A !important" : "#C7CCD0 !important",
        fontSize: "14px !important",
        fontFamily: "Roboto !important",
        fontWeight: 400,
      },
      "& .MuiInputLabel-root": {
        color: "#7B809A !important",
        fontSize: "18px !important",
        fontFamily: "Roboto !important",
        fontWeight: 400,
      },
    },
  };

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <MDTypography variant="h5" sx={{ color: "#344767" }}>
          Basic Info
        </MDTypography>
        <MDBox mt={3}>
          {!isEditing ? (
            <MDButton
              variant="contained"
              onClick={() => setIsEditing(true)}
              sx={{
                color: "#000000",
                backgroundColor: "#FFFFFF",
                border: "1px solid #1C1C1C",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#E5E9EF",
                  color: "#000000",
                },
              }}
            >
              Edit
            </MDButton>
          ) : (
            <>
              <MDButton
                variant="contained"
                onClick={() => handleCancel()}
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1C1C",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#E5E9EF",
                    color: "#000000",
                  },
                  marginRight: "5px",
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                onClick={handleSave}
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1C1C",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                }}
                disabled={isUpdating || !activeUserId}
                startIcon={isUpdating ? null : <SaveOutlinedIcon sx={{ mb: "1px" }} />}
              >
                {isUpdating ? "Saving..." : "Save"}
              </MDButton>
            </>
          )}
        </MDBox>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        {isFetching ? (
          <div>Loading profile...</div> // Display loading text or a spinner
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="First Name"
                value={formData.firstName}
                onChange={(e: any) => handleChange("firstName", e.target.value)}
                disabled={!isEditing}
                {...formFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                value={formData.lastName}
                onChange={(e: any) => handleChange("lastName", e.target.value)}
                disabled={!isEditing}
                {...formFieldStyles}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                value={formData.email}
                onChange={(e: any) => handleChange("email", e.target.value)}
                inputProps={{ type: "email" }}
                disabled
                {...formFieldStyles}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                value={formData.phoneNumber}
                onChange={(e: any) => handleChange("phoneNumber", e.target.value)}
                inputProps={{ type: "number" }}
                disabled={!isEditing}
                {...formFieldStyles}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Your Location"
                value={formData.location}
                onChange={(e: any) => handleChange("location", e.target.value)}
                disabled={!isEditing}
                {...formFieldStyles}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </Card>
  );
};

export default BasicInfo;
