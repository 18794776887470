import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { addAdminUser } from "services/ventureApi";
import { setAddStatus, setAddMessage, addUserToState } from "features/admin/adminSlice";
import { AddAdminUserRequest } from "services/ventureApi";
import { useAdminUsersData } from "./useAdminUsersData"; // Import your hook here

export const useAddAdminUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { addStatus, addMessage } = useSelector((state: RootState) => state.admin);
  const { refetch } = useAdminUsersData(); // Get the refetch function from your hook

  const [loading, setLoading] = useState(false);

  const handleAddAdminUser = async (userDetails: AddAdminUserRequest) => {
    setLoading(true);
    dispatch(setAddStatus("loading"));

    try {
      const response = await addAdminUser(userDetails);

      if (response.success && response.createdUser) {
        dispatch(addUserToState(response.createdUser));
        dispatch(setAddStatus("success"));
        dispatch(setAddMessage("Admin user added successfully."));

        // Trigger refetch to update the admin users list
        refetch();
      } else {
        dispatch(setAddStatus("error"));
        dispatch(setAddMessage(response.message || "Failed to add admin user."));
      }
    } catch (error) {
      console.error("Error adding admin user:", error);
      dispatch(setAddStatus("error"));
      dispatch(setAddMessage("An unexpected error occurred."));
    } finally {
      setLoading(false);
    }
  };

  return {
    handleAddAdminUser,
    loading,
    addStatus,
    addMessage,
  };
};
