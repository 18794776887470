import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { getAdminUsers, AdminUserResponse } from "services/ventureApi";
import { setAdminUsersData } from "features/admin/adminSlice";

export const useAdminUsersData = () => {
  const dispatch = useDispatch();

  const { data, isLoading, error, refetch } = useQuery<AdminUserResponse[], Error>({
    queryKey: ["adminUsersData"],
    queryFn: () => getAdminUsers(),
    staleTime: 30 * 60 * 1000, // Cache for 30 minutes
  });

  useEffect(() => {
    if (data) {
      dispatch(setAdminUsersData(data));
    }
  }, [data, dispatch]);

  return {
    adminUsersData: data || [],
    isAdminUsersLoading: isLoading,
    adminUsersError: error,
    refetch,
  };
};
