import { useDispatch, useSelector } from "react-redux";
import {
  fetchEntityStart,
  fetchEntityFailure,
  fetchEntitySuccess,
} from "features/entity/entitySlice";
import { RootState } from "app/store";
import { getEntities, EntityResponse } from "services/ventureApi";

// Custom hook for fetching entity details
export const useFetchEntity = () => {
  const dispatch = useDispatch();
  const { entity, loading, error } = useSelector((state: RootState) => state.entity);

  const fetchEntity = async (entity_id: number) => {
    dispatch(fetchEntityStart());
    try {
      const entityData: EntityResponse = await getEntities(entity_id);
      dispatch(fetchEntitySuccess(entityData));
    } catch (err) {
      dispatch(fetchEntityFailure("Failed to fetch entity"));
    }
  };

  return { entity, loading, error, fetchEntity };
};
