import React, { useState, useEffect } from "react";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import { IconButton, Avatar, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import entityPlaceholder from "assets/images/reisiger/BierMier.webp";
import { UserForAdmin, deleteUserForAdmin } from "services/ventureApi";
import { useRemoveUser } from "hooks/admin/useRemoveUser";
import { useDelinkUserForAdmin } from "hooks/admin/useDelinkUserForAdmin";
import { number } from "yup";
import { retrieveMedia } from "services/ventureApi";

interface Entity {
  entity_id: number;
  entity_name?: string;
  role_name?: string;
  user_count?: number;
  user_role?: string;
  team_role?: string;
  entity_icon?: string;
}

interface User {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  date_added: string;
  location?: string;
  entities?: Entity[];
  user_icon?: string;
}

interface UserDetailPanelProps {
  user: User | null;
  onClose: () => void;
}

const UserDetailPanel: React.FC<UserDetailPanelProps> = ({ user, onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const { deleteUser, deleteUserError } = useRemoveUser();
  const { delinkUser, delinkError } = useDelinkUserForAdmin();
  if (!user) return null;
  console.log(user);

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const handleDelete = () => {
    if (!user || !user.user_id) {
      alert("User ID is required.");
      return;
    }

    setIsDeleting(true);
    deleteUser(user.user_id, {
      onSuccess: () => {
        setIsDeleting(false);
        onClose(); // Close panel after successful deletion
      },
      onError: () => {
        setIsDeleting(false);
        alert("Failed to delete user. Please try again.");
      },
    });
  };

  const handleDelink = (entityId: number) => {
    if (!user || !user.user_id) {
      alert("User ID is required.");
      return;
    }

    delinkUser(
      { userId: user.user_id, entityId },
      {
        onSuccess: () => alert("User successfully delinked from entity"),
        onError: () => alert("Failed to delink user. Please try again."),
      }
    );
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        // Use the entityId to fetch the media
        const mediaResponse = await retrieveMedia("user_profile");
        if (mediaResponse.presignedUrl) {
          setProfileImage(mediaResponse.presignedUrl);
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    fetchProfileImage();
    // setCurrentEntity(loginResponse?.entity.entity_id); // only for entities not profile
  }, [profileImage]);

  return (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
        //p: "20px",
        boxShadow: "md",
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        zIndex: 100,
      }}
    >
      <IconButton onClick={onClose} sx={{ alignSelf: "flex-end" }}>
        <CloseIcon />
      </IconButton>
      <Avatar
        src={profileImage || entityPlaceholder}
        sx={{ width: 80, height: 80, mb: 2 }}
        variant="rounded"
      />
      <MDBox display="flex" flexDirection="column" gap={1}>
        <MDBox display="flex" justifyContent="space-between" px={2}>
          <MDTypography variant="body2" fontWeight="bold">
            Full Name:
          </MDTypography>
          <MDTypography variant="body2">
            {user.first_name} {user.last_name}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" px={2}>
          <MDTypography variant="body2" fontWeight="bold">
            Email:
          </MDTypography>
          <MDTypography variant="body2">{user.email}</MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" px={2}>
          <MDTypography variant="body2" fontWeight="bold">
            Location:
          </MDTypography>
          <MDTypography variant="body2">{user.location}</MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" px={2}>
          <MDTypography variant="body2" fontWeight="bold">
            Date Added:
          </MDTypography>
          <MDTypography variant="body2">{formatDate(user.date_added)}</MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" px={2}>
          <MDTypography variant="body2" fontWeight="bold">
            User ID:
          </MDTypography>
          <MDTypography variant="body2">{user.user_id}</MDTypography>
        </MDBox>
      </MDBox>

      <MDBox display="flex" justifyContent="center" mt={2}>
        <MDButton
          variant="outlined"
          color="primary"
          startIcon={isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />}
          disabled={isDeleting}
          onClick={handleDelete}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {isDeleting ? "Deleting..." : "DELETE USER"}
        </MDButton>
      </MDBox>

      {user.entities && user.entities.length > 0 && (
        <>
          <MDTypography variant="body1" fontWeight="bold" mt={3} mb={1}>
            User’s Entities
          </MDTypography>
          <MDBox display="flex" flexDirection="column" gap={1} alignItems="center">
            {user.entities.map((entity) => (
              <EntityItem key={entity.entity_id} entity={entity} onDelink={handleDelink} />
            ))}
          </MDBox>
        </>
      )}
    </MDBox>
  );
};

const DetailRow: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <MDBox display="flex" justifyContent="space-between" px={2}>
    <MDTypography variant="body2" fontWeight="bold">
      {label}:
    </MDTypography>
    <MDTypography variant="body2">{value}</MDTypography>
  </MDBox>
);

/* EntityItem - Entity List Row */
const EntityItem: React.FC<{ entity: Entity; onDelink: (entityId: number) => void }> = ({
  entity,
  onDelink,
}) => (
  <MDBox
    display="flex"
    alignItems="center"
    width="100%"
    maxWidth={400}
    justifyContent="space-between"
    p={1}
    sx={{
      borderRadius: "8px",
      //backgroundColor: "#F8F9FA",
      //boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    }}
  >
    {/* Left: Entity Avatar */}
    <Avatar
      src={entity.entity_icon || entityPlaceholder}
      sx={{ width: 40, height: 40, borderRadius: "8px" }}
    />

    {/* Middle: Entity Details */}
    <MDBox display="flex" flexDirection="column" flex={1} ml={2}>
      <MDTypography variant="body2" fontWeight="bold">
        {entity.entity_name}
      </MDTypography>
      <MDTypography variant="caption" fontSize="10px">
        User Role: {entity.role_name}
      </MDTypography>
      <MDTypography variant="caption" fontSize="10px">
        Team Role: {entity.role_name}
      </MDTypography>
    </MDBox>

    {/* Right: DELINK Button */}
    <MDTypography
      variant="body2"
      fontSize="12px"
      fontWeight="bold"
      color="error"
      sx={{ cursor: "pointer" }}
      onClick={() => onDelink(entity.entity_id)}
    >
      DELINK
    </MDTypography>
  </MDBox>
);

export default UserDetailPanel;
