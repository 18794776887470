import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/generic/MDBox";
import UpdatedDashboardLayout from "components/refined/LayoutContainers/VentureDashboardLayout";
import HeaderSettings from "./header";
import BasicInfo from "./components/BasicInfo";
import headerImage from "assets/images/startup-profile/settings-header.png";
import Sidenav from "./components/SideNav";
import Header from "./components/Header";
import bgImage from "assets/images/reisiger/backgroundImage.png";
import DeleteAccount from "./components/DeleteAccount";

const InvestorEntitySettingsPage: React.FC = () => {
  return (
    <UpdatedDashboardLayout>
      <HeaderSettings backgroundImage={headerImage}>
        <MDBox sx={{ width: "100%" }} mt={4}>
          <Grid container spacing={3}>
            {/* SideNav Column */}
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>

            {/* Profile Header and Content */}
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                {" "}
                <Header />
              </MDBox>

              {/* Main Content */}
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BasicInfo />
                  </Grid>
                </Grid>
              </MDBox>
              {/* Delete Account */}
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <DeleteAccount />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </HeaderSettings>
    </UpdatedDashboardLayout>
  );
};

export default InvestorEntitySettingsPage;
