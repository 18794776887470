const endpoints = {
  demo: {
    fetchData: "/demo/fetchData", // Example endpoint
    submitData: "/demo/submitData", // Example endpoint
  },
  auth: {
    login: "/auth/login",
    signup: "/auth/signup",
    requestResetPassword: "/auth/request-reset", // Placeholder for request reset password endpoint
    verifyResetCode: "/auth/verify-reset-code", // Placeholder for verifying the reset code
    confirmResetPassword: "/auth/reset-password", // Placeholder for confirming the new password
    teamMemberConfirmation: "auth/team-member/confirmation",
    newTeamMember: "/auth/team-member/init",
  },
  datatransfers: {
    uploadRequest: "/datatransfers/upload-request",
    validateUpload: "/datatransfers/validate-upload",
    retrieveMedia: "/datatransfers/retrieve-media",
  },
  web: {
    gqSteps: "/web/onboarding/gq",
    termsConditions: "/web/onboarding/terms-conditions",
    validateBusinessRegistration: "/web/onboarding/onboarding-validations",
  },
  connections: {
    getBusinesses: "/connections/businesses", // New endpoint for retrieving businesses
    getBusinessProfile: "/connections/business-profile",
    createConnectionRequest: "/connections/create-connection-request",
  },
  integrations: {
    clickUp: {
      clientId: "/integrations/clickup/client-id", // Fetch ClickUp client ID
      addToken: "/integrations/clickup/add-token", // Add ClickUp token
      overview: "/integrations/clickup-overview", // Get ClickUp overview for an entity
    },
  },
  dashboard: {
    teamMembers: "/dashboard", // Fetch team members for an entity
  },
  // Add more in same style
};

export default endpoints;
