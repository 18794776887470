/*
// this file is for importing the entire app's routesConfig.json and filtering for the routes to display according to the user's role.
// then building the JSX array from the filtered json  
// 1] import routes config json 
// 2] import layout components
// 3] resolve components and icons
// 4] filter json according to role tag
// 5] build filteredJSON into JSX array
// 6] export JSX array to be used in rest of app

//TODO: integrate with auth context.. user will be signed in, will be able to fetch routes via authContext's user.role.
*/

import MDAvatar from "components/generic/MDAvatar";
import Icon from "@mui/material/Icon";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
//import routesConfig from "./routesConfig.json";
//const routesConfig = require("./routesConfig.json");
import { routesConfig } from "./routesConfig";

// Import all the components used in your routes
import ProfileOverview from "../layouts/pages/profile/profile-overview";
import Settings from "../layouts/pages/account/settings";
import SignInBasic from "../layouts/authentication/sign-in/basic";
//import SignIn from "../layouts/authentication/sign-in/SignIn";
import Analytics from "../layouts/dashboards/analytics";
import Sales from "../layouts/dashboards/sales";
import AllProjects from "../layouts/pages/profile/all-projects";
import NewUser from "../layouts/pages/users/new-user";
import Billing from "../layouts/pages/account/billing";
import Invoice from "../layouts/pages/account/invoice";
import Timeline from "../layouts/pages/projects/timeline";
import PricingPage from "../layouts/pages/pricing-page";
import RTL from "../layouts/pages/rtl";
import Widgets from "../layouts/pages/widgets";
import Charts from "../layouts/pages/charts";
import Notifications from "../layouts/pages/notifications";
import Kanban from "../layouts/applications/kanban";
import Wizard from "../layouts/applications/wizard";
import DataTables from "../layouts/applications/data-tables";
import Calendar from "../layouts/applications/calendar";
import NewProduct from "../layouts/ecommerce/products/new-product";
import EditProduct from "../layouts/ecommerce/products/edit-product";
import ProductPage from "../layouts/ecommerce/products/product-page";
import OrderList from "../layouts/ecommerce/orders/order-list";
import OrderDetails from "../layouts/ecommerce/orders/order-details";
import SignInCover from "../layouts/authentication/sign-in/cover";
import SignInIllustration from "../layouts/authentication/sign-in/illustration";
import SignUpCover from "../layouts/authentication/sign-up/cover";
import ResetCover from "../layouts/authentication/reset-password/cover";
import UnauthorizedAccess from "../layouts/utility/unauthorized-access";
import LoadingPage from "../layouts/utility/loading-page";
import Home from "../layouts/pages/home";
import { ReactNode } from "react";
//app routes
import SignIn from "layouts/authentication/sign-in/SignIn";
import ResetPassword from "layouts/authentication/reset-password/ResetPassword";
import NewPassword from "layouts/authentication/reset-password/NewPassword";
import ResetCode from "layouts/authentication/reset-password/ResetCode";
import SignUp from "layouts/authentication/sign-up/SignUp";
import UserEmailConfirmation from "layouts/authentication/sign-up/user-email-confirmation";
//investor
import InvestorGQ from "layouts/pages/investor/investor-questionaire/InvestorGQ";
import InvestorProfile from "layouts/pages/investor/investor-profile/InvestorProfile";
import MyPortfolio from "layouts/pages/investor/investor-portifolio/MyPortifolio";
//admin
import AdminLayout from "layouts/pages/administrators/platformOwner/AdminLayout"; //Adminisitrators portal
import AdminUsers from "layouts/pages/administrators/portal/users/AdminUsers";
import AdminEntities from "layouts/pages/administrators/portal/entities/AdminEntities";
//business
import BusinessGQ from "layouts/pages/business/business-questionaire/BusinessGQ";
import BusinessProfile from "layouts/pages/business/business-profile/BusinessProfile";
//profile
import UserProfileSettingsPage from "layouts/pages/profile/user-profile-settings/UserProfileSettingsPage";
//profile/profile-build
import ProfileBuildingAgreement from "layouts/pages/profile/profile-build/t-and-cs";
import WelcomePage from "layouts/pages/profile/profile-build/welcome-page";
import BusinessEntitySettingsPage from "layouts/pages/profile/business-profile-settings/BusinessEntitySettingsPage";
import InvestorEntitySettingsPage from "layouts/pages/profile/investor-profile-settings/InvestorEntitySettingsPage";

//import { useAuth } from "../context/AuthContext";

//const { user } = useAuth();

//const role = "admin"; // user?.role || <- prepend later

//utility function to resolve the component from the component name
const resolveComponent = (componentName: string): ReactNode => {
  switch (componentName) {
    case "ProfileOverview":
      return <ProfileOverview />;
    case "Settings":
      return <Settings />;
    case "SignInBasic":
      return <SignInBasic />;
    case "Analytics":
      return <Analytics />;
    case "Sales":
      return <Sales />;
    case "AllProjects":
      return <AllProjects />;
    case "NewUser":
      return <NewUser />;
    case "Billing":
      return <Billing />;
    case "Invoice":
      return <Invoice />;
    case "Timeline":
      return <Timeline />;
    case "PricingPage":
      return <PricingPage />;
    case "RTL":
      return <RTL />;
    case "Widgets":
      return <Widgets />;
    case "Charts":
      return <Charts />;
    case "Notifications":
      return <Notifications />;
    case "Kanban":
      return <Kanban />;
    case "Wizard":
      return <Wizard />;
    case "DataTables":
      return <DataTables />;
    case "Calendar":
      return <Calendar />;
    case "NewProduct":
      return <NewProduct />;
    case "EditProduct":
      return <EditProduct />;
    case "ProductPage":
      return <ProductPage />;
    case "OrderList":
      return <OrderList />;
    case "OrderDetails":
      return <OrderDetails />;
    case "SignInCover":
      return <SignInCover />;
    case "SignInIllustration":
      return <SignInIllustration />;
    case "SignUpCover":
      return <SignUpCover />;
    case "ResetCover":
      return <ResetCover />;
    case "UnauthorizedAccess":
      return <UnauthorizedAccess />;
    case "LoadingPage":
      return <LoadingPage />;
    case "Home":
      return <Home />;
    case "SignIn":
      return <SignIn />;
    case "SignUp":
      return <SignUp />;
    case "ResetPassword":
      return <ResetPassword />;
    case "NewPassword":
      return <NewPassword />;
    case "ResetCode":
      return <ResetCode />;
    case "InvestorGQ":
      return <InvestorGQ />;
    case "BusinessGQ":
      return <BusinessGQ />;
    case "UserEmailConfirmation":
      return <UserEmailConfirmation />;
    case "ProfileBuildingAgreement":
      return <ProfileBuildingAgreement />;
    case "WelcomePage":
      return <WelcomePage />;
    case "BusinessProfile":
      return <BusinessProfile />;
    case "InvestorProfile":
      return <InvestorProfile />;
    case "UserProfileSettingsPage":
      return <UserProfileSettingsPage />;
    case "BusinessEntitySettingsPage":
      return <BusinessEntitySettingsPage />;
    case "InvestorEntitySettingsPage":
      return <InvestorEntitySettingsPage />;
    case "MyPortfolio":
      return <MyPortfolio />;
    case "AdminLayout":
      return <AdminLayout setActiveTab={null} activeTab={null} />;
    case "AdminUsers":
      return <AdminUsers setActiveTab={null} activeTab={null} />;
    case "AdminEntities":
      return <AdminEntities />;
    default:
      return null;
  }
};

//utility function to resolve the icon from the icon name and properties
const resolveIcon = (iconName: string, iconProps: any): ReactNode => {
  switch (iconName) {
    case "MDAvatar":
      return <MDAvatar {...iconProps} />;
    case "Icon":
      return <Icon {...iconProps} />;
    default:
      console.warn(`Icon not found: ${iconName}`);
      return null;
  }
};
//change to filterRoutesByRolePermissionsAndEntityType
// will look at LoginResponse.entity.additional_info.profile.entityType = "Business" or similar
export const filterRoutesByRoleAndPermissions = (
  config: any,
  role: string,
  permissions: Set<string>,
  entityType: string,
  gq_state: string
): any => {
  return config
    .map((route: any) => {
      //console.log("Checking route: ", route);
      if (role !== "public" && route.tag === "public") {
        return null;
      }

      if (route.tag && route.tag !== "public" && route.tag !== role) {
        //include public roles and routes tha match role
        return null;
      }

      /* pre-public routes
      if (route.tag && route.tag !== "role") {
        return null; //skip non tagged routes for now - common routes may be non tagged? - or introduce public tag
      }
      */
      // Check for entityType (*add route.entityType !== "public/all"? or will above check do - test)
      if (route.entityType && route.entityType !== entityType) {
        return null;
      }
      /* dynamic sidebar based on gq state filter - only show sign out route -> key should be login
      if (gq_state !== "BUSINESS_COMPLETE" || "INVESTOR_COMPLETE" && route.key !== "logout") {
        return null;
      }
      */

      if (route.key && !permissions.has(route.key)) {
        return null;
      }

      if (route.collapse) {
        const filteredCollapse = filterRoutesByRoleAndPermissions(
          route.collapse,
          role,
          permissions,
          entityType,
          gq_state
        );
        if (filteredCollapse.length > 0) {
          return { ...route, collapse: filteredCollapse };
        }
        return null;
      }
      /*
      if (route.type === "title" || route.type === "divider") {
        return route.tag === role || route.tag === "public" ? route : null;
      }
      */
      if (route.type === "divider" || route.type === "title") {
        return route;
      }

      if (route.type === "page") {
        //console.log("type page, route: ", route);
        return route;
      }
      //console.log("Final route object:", route);
      return route;
    })
    .filter(Boolean);
};

export const buildRoutes = (config: any, entityName: string): any => {
  return config.map((route: any) => {
    if (route.collapse) {
      return {
        type: route.type || "collapse",
        name: route.name,
        key: route.key,
        icon: resolveIcon(route.icon, route.iconProps),
        tag: route.tag,
        collapse: buildRoutes(route.collapse, entityName), // Pass entityName to nested routes
        showInSidebar: route.showInSidebar,
      };
    } else if (route.route && route.component) {
      // Only change the profile routes (Business Profile / Investor Profile)
      if (route.name === "Business Profile" || route.name === "Investor Profile") {
        return {
          type: route.type || "page",
          name: entityName, // Set entityName instead of static "Business Profile" or "Investor Profile"
          key: route.key,
          route: route.route,
          component: resolveComponent(route.component),
          tag: route.tag,
          showInSidebar: route.showInSidebar,
          noCollapse: route.noCollapse,
          icon: resolveIcon(route.icon, route.iconProps), // Keep the existing icon props
        };
      } else {
        return {
          type: route.type || "page",
          name: route.name,
          key: route.key,
          route: route.route,
          component: resolveComponent(route.component),
          tag: route.tag,
          showInSidebar: route.showInSidebar,
          noCollapse: route.noCollapse,
          icon: resolveIcon(route.icon, route.iconProps),
        };
      }
    } else if (route.type === "divider") {
      return {
        type: "divider",
        key: route.key,
        showInSidebar: route.showInSidebar,
      };
    } else if (route.href) {
      return {
        type: route.type,
        name: route.name,
        key: route.key,
        href: route.href,
        noCollapse: route.noCollapse,
        icon: resolveIcon(route.icon, route.iconProps),
        tag: route.tag || "",
      };
    } else if (route.type === "title") {
      return {
        type: route.type,
        key: route.key,
        title: route.title,
        tag: route.tag,
        showInSidebar: route.showInSidebar,
      };
    } else {
      console.warn("**Route did not match any type, returning null:", route);
      return null;
    }
  });
};
