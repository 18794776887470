import { useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import MDBox from "components/generic/MDBox";
import { useMaterialUIController, setLayout } from "context";
import Footer from "components/refined/Footer";

function UpdatedDashboardLayout({ children }: { children: ReactNode }): JSX.Element {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        position: "relative",
        padding: 0,
        px: 1,
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {/* Page Content */}
      <MDBox component="main" flexGrow={1}>
        {children}
      </MDBox>

      {/* Footer at the bottom */}
      <MDBox component="footer" mt="auto">
        {/* Import and render Footer component here */}
        <Footer />
      </MDBox>
    </MDBox>
  );
}

export default UpdatedDashboardLayout;
