import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import entityPlaceholder from "assets/images/reisiger/BierMier.webp"; // or your placeholder

import { AdminEntity } from "services/ventureApi";

type EntityKeys = keyof AdminEntity;
const TableHeaderCell = styled(TableCell)(() => ({
  //fontWeight: "bold",
  //display: "flex",
  //alignItems: "center",
  color: "#1C1C1C",
  fontSize: "12px",
  fontFamily: "Titillium Web,sans-serif",
  fontWeight: 700,
}));

interface EntitiesAdminTableProps {
  entities: AdminEntity[];
  isLoading?: boolean;
  onViewEntity: (entity: AdminEntity) => void;
}

const EntitiesAdminTable: React.FC<EntitiesAdminTableProps> = ({
  entities,
  isLoading,
  onViewEntity,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState<{
    key?: EntityKeys;
    direction: "asc" | "desc";
  }>({ key: undefined, direction: "asc" });

  // Filter + sort
  const displayedEntities = useMemo(() => {
    let data = [...entities];

    // Filter by name, type, etc. if needed
    if (searchQuery.trim()) {
      const lowerQuery = searchQuery.toLowerCase();
      data = data.filter(
        (ent) =>
          ent.entity_name.toLowerCase().includes(lowerQuery) ||
          ent.entity_type.toLowerCase().includes(lowerQuery)
      );
    }

    // Sorting
    if (sortConfig.key) {
      data.sort((a, b) => {
        const aValue = (a as any)[sortConfig.key] ?? "";
        const bValue = (b as any)[sortConfig.key] ?? "";
        return sortConfig.direction === "asc"
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      });
    }

    return data;
  }, [searchQuery, sortConfig, entities]);

  const handleSort = (key: EntityKeys) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <MDBox
      sx={{
        width: "100%",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
        p: 3,
      }}
    >
      <MDTypography variant="h5" fontWeight={700} sx={{ mb: 2, color: "#4C4E52" }}>
        Entities
      </MDTypography>

      <Box sx={{ px: 4, width: "100%", mb: "24px" }} display={"flex"} alignItems="center">
        <TextField
          label="Search Entities"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
          InputProps={{
            sx: {
              color: "#1C1C1C !important",
              "& .MuiInputBase-input": {
                color: "#1C1C1C !important",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#1C1C1C !important",
                opacity: 1,
              },
            },
          }}
          InputLabelProps={{
            sx: {
              color: "#1C1C1C !important",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#7B809A !important",
              },
              "&:hover fieldset": {
                borderColor: "#7B809A !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#7B809A !important",
              },
            },
          }}
          placeholder="Search here"
        />
      </Box>

      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "auto",
            "&::-webkit-scrollbar": { width: "6px", height: "6px" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
          }}
        >
          <Table>
            <TableHead
              sx={{
                display: "table-header-group",
              }}
            >
              <TableRow>
                {[
                  { label: "Entity ID", key: "entity_id" },
                  { label: "Entity Name", key: "entity_name" },
                  { label: "Entity Type", key: "entity_type" },
                  { label: "Date Created", key: "date_added" },
                  { label: "Users", key: "users" },
                  { label: "Actions", key: "actions" },
                ].map((col) => (
                  <TableHeaderCell
                    key={col.key}
                    onClick={() => col.key !== "actions" && handleSort(col.key as EntityKeys)}
                    sx={{ cursor: col.key !== "actions" ? "pointer" : "default" }}
                  >
                    {col.label.replace("_", " ")}
                    {col.key !== "actions" && (
                      <UnfoldMoreIcon
                        fontSize="small"
                        sx={{
                          //marginLeft: "30%",
                          marginBottom: "-2px",
                          color: "#1C1C1C",
                          //width: "20px",
                          height: "12px",
                          cursor: "pointer",
                          verticalAlign: "middle",
                        }}
                      />
                    )}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {displayedEntities.map((entity) => (
                <TableRow key={entity.entity_id}>
                  <TableCell>{entity.entity_id}</TableCell>
                  <TableCell>{entity.entity_name}</TableCell>
                  <TableCell>{entity.entity_type}</TableCell>
                  <TableCell>{formatDate(entity.date_added)}</TableCell>

                  {/* Users Avatars / Thumbnails */}
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {entity.users && entity.users.length > 0 ? (
                        entity.users.slice(0, 3).map((user, idx) => (
                          <Avatar
                            key={idx}
                            src={user.user_icon || entityPlaceholder} // or user thumbnail if you have it
                            alt={user.first_name}
                            sx={{
                              width: 32,
                              height: 32,
                              border: "2px solid white",
                              ml: idx > 0 ? -1 : 0,
                            }}
                          />
                        ))
                      ) : (
                        <MDTypography variant="caption">No users</MDTypography>
                      )}
                      {entity.users && entity.users.length > 3 && (
                        <Avatar
                          sx={({ borders: { borderWidth }, palette: { white } }) => ({
                            border: `${borderWidth[2]} solid ${white.main}`,
                            cursor: "pointer",
                            position: "relative",
                            width: 32,
                            height: 32,
                            ml: -1.25,
                            "&:hover, &:focus": {
                              zIndex: "10",
                            },
                          })}
                        >
                          +{entity.users.length - 3}
                        </Avatar>
                      )}
                    </Box>
                  </TableCell>

                  {/* Actions */}
                  <TableCell>
                    <IconButton color="primary" onClick={() => onViewEntity(entity)} size="small">
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    {/* You can add more actions here (e.g. delete button) */}
                  </TableCell>
                </TableRow>
              ))}

              {displayedEntities.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <MDTypography variant="body2" textAlign="center" color="text.secondary">
                      No entities found.
                    </MDTypography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </MDBox>
  );
};

export default EntitiesAdminTable;
