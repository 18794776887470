import React, { useState } from "react";
import { Grid } from "@mui/material";

import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import AdminNavbar from "../components/AdminNavbar"; // if you have one
import KpiSection from "./components/KpiSection"; // or reuse from users if same structure
import EntitiesAdminTable from "./components/EntitiesAdminTable";
import EntityDetailPanel from "./components/EntityDetailPanel";

import { useEntitiesForAdminData } from "hooks/admin/useEntitiesForAdminData";
import { useEntitiesKpiData } from "hooks/admin/useEntitiesKpiData";
import { AdminEntity } from "services/ventureApi";

/*
type AdminUsersLayoutProps = {
  setActiveTab?: (state: number) => void;
  activeTab?: number;
};
*/

function AdminEntities(): JSX.Element {
  const [selectedEntity, setSelectedEntity] = useState<AdminEntity | null>(null);

  // 1) Grab Entities KPI stats
  const { entityKpiStats, isEntitiesKpiLoading } = useEntitiesKpiData();

  // 2) Grab Entities list
  const { entities, isEntitiesLoading } = useEntitiesForAdminData();

  return (
    <DashboardLayout>
      {/* Top Nav Bar */}
      <AdminNavbar />

      {/* KPI Section */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {/* Reuse KpiSection but pass entityKpiStats if you want to display them similarly */}
          <KpiSection data={entityKpiStats} isLoading={isEntitiesKpiLoading} />
        </Grid>

        {/* Table (left) */}
        <Grid
          item
          xs={selectedEntity ? 8 : 12}
          sx={{
            height: "100%",
            overflow: "hidden",
            transition: "width 0.3s ease",
          }}
        >
          <EntitiesAdminTable
            entities={entities}
            isLoading={isEntitiesLoading}
            onViewEntity={setSelectedEntity}
          />
        </Grid>

        {/* Detail Panel (right) */}
        {selectedEntity && (
          <Grid
            item
            xs={4}
            sx={{
              height: "100%",
              overflowY: "auto",
              paddingLeft: "16px",
              transition: "width 0.3s ease",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            }}
          >
            <EntityDetailPanel entity={selectedEntity} onClose={() => setSelectedEntity(null)} />
          </Grid>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default AdminEntities;
