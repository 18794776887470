import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

import ViewListIcon from "@mui/icons-material/ViewList";
import DeleteIcon from "@mui/icons-material/Delete";
import GridViewIcon from "@mui/icons-material/GridView";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import AdminCard from "../components/AdminCard";
import DeleteAdminModal from "../components/DeleteAdminModal";
import AddAdminModal from "../components/AddAdminModal";
import backgroundImage from "assets/images/bg-profile.jpeg";
import AdminNavbar from "../components/AdminNavbar";
//
import UsersAdminTable from "./components/UsersAdminTable";
import KpiSection from "./components/KpiSection";
import UserDetailPanel from "./components/UserDetailPanel";

import { useUsersForAdminData } from "hooks/admin/useUsersForAdminData";
import { useAdminKpiData } from "hooks/admin/useUsersAdminKpiData";

const dummyUsers = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    dateAdded: "2025-01-10",
    entities: [{}],
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    dateAdded: "2025-01-11",
    entities: [{}],
  },
];

type AdminUsersLayoutProps = {
  setActiveTab?: (state: number) => void;
  activeTab?: number;
};

function AdminUsers({ setActiveTab, activeTab }: AdminUsersLayoutProps): JSX.Element {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isTableView, setIsTableView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" | null }>({
    key: "",
    direction: "asc",
  });
  const [selectedUser, setSelectedUser] = useState(null);

  const { adminUsersData, isAdminUsersLoading } = useUsersForAdminData();
  const { adminKpiData, isAdminKpiLoading } = useAdminKpiData();

  //new users table...
  //columns: User ID, User Name, User Surname, Email, Date Added, Entities, Actions.
  //Notes: Entities' cells contains icons that shows a thumbnail image of the entity image. if one, its a left aligned rounded thumbnoal.
  //if more, layered atop each other, stacking right, showing max of 5, 6th thumbnail shows +# entities.
  //Actions notes: view icon button (eye icon) that opens the seelcted user in a menu to the right of the table.
  //grid based layout, side menu should take up the right third, with table the left two thirds. above the table, there should be a kpi header, with double row text blocks,
  // divided by vertical dividers.
  //notes on columns, each sortable heading should have sort buttons after teh header title.
  //kpi header is flush with the backgorund, then the table/view menu section is on rounded md boxes. table sections has users heading, then a full width search bar, then the table.
  //view menu has top right refresh and colse button.will display user details.
  // Dummy data generation
  const generateDummyRows = (count: number) => {
    return Array.from({ length: count }, (_, index) => ({
      info: `User ${index + 1} Name & Surname`,
      tag: index % 2 === 0 ? "Platform Admin" : "Moderator",
      emailAddress: `user${index + 1}@example.com`,
      dateAdded: `2025-01-${String(index + 1).padStart(2, "0")}`,
      image: "/image-profilepic.jpg",
    }));
  };

  const tableData = {
    rows: generateDummyRows(40),
  };

  useEffect(() => {
    console.log("selectedUser", selectedUser);
  }, [selectedUser]);

  // Filter rows based on search query
  const filteredRows = tableData.rows.filter(
    (row) =>
      row.info.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.tag.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.emailAddress.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDeleteClick = (itemName: string) => {
    setSelectedItemName(itemName); // Store the name of the item to be deleted
    setShowDeleteModal(true); // Show the delete modal
  };

  const handleAddClick = () => {
    setSelectedItemName(null); // Store the name of the item to be deleted
    setShowAddModal(true); // Show the delete modal
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
    setShowAddModal(false);
    setSelectedItemName(null);
  };

  const handleSort = (key: string) => {
    const direction =
      sortConfig.key === key ? (sortConfig.direction === "asc" ? "desc" : "asc") : "asc"; // Default to ascending if a new column is clicked
    setSortConfig({ key, direction });
  };

  const sortedRows = [...filteredRows].sort((a: any, b: any) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  const customFontStyle = {
    color: "#1C1C1C",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
  };

  const customHeaderStyle = {
    color: "#1C1C1C",
    fontSize: "12px",
    fontFamily: "Titillium Web,sans-serif",
    fontWeight: 700,
  };

  return (
    <DashboardLayout>
      <AdminNavbar />

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <KpiSection data={adminKpiData} />
        </Grid>
        <Grid
          item
          xs={selectedUser ? 8 : 12}
          sx={{
            height: "100%",
            overflow: "hidden",
            transition: "width 0.3s ease",
          }}
        >
          {" "}
          <UsersAdminTable
            onViewUser={setSelectedUser}
            users={adminUsersData}
            isLoading={isAdminUsersLoading}
          />
        </Grid>
        {selectedUser && (
          <Grid
            item
            xs={4}
            sx={{
              height: "100%",
              overflowY: "auto",
              paddingLeft: "16px",
              transition: "width 0.3s ease",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            }}
          >
            <UserDetailPanel user={selectedUser} onClose={() => setSelectedUser(null)} />
          </Grid>
        )}
      </Grid>

      {showDeleteModal && (
        <DeleteAdminModal onClose={handleModalClose} itemName={selectedItemName} />
      )}
      {showAddModal && <AddAdminModal onClose={handleModalClose} itemName={selectedItemName} />}
    </DashboardLayout>
  );
}

export default AdminUsers;
