//import axios, { AxiosInstance } from "axios"; declared in api>axiosInstance
import axios from "axios"; //or fine if use axiosInstance for all - validate
import { axiosInstance } from "api/axiosInstance";
import endpoints from "api/endpoints";
//import { refreshToken } from './auth'; not used currently
import getConfig from "./config";
import { Config, LoginResponse, RegistrationData } from "../types/authTypes";
import { DetailedEntityResponse } from "../types/adminTypes";

const config: Config = getConfig;
/*
const apiBaseUrl = `${config.apiUrl}/${config.stage}/`;
//initial auth axios instance, rest with other axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("idToken");
    const expirationTime = localStorage.getItem("tokenExpiration");

    if (expirationTime && new Date().getTime() > parseInt(expirationTime)) {
      // Token is expired, refresh it
      try {
        const response = await axios.post("/refresh-token", {}, { withCredentials: true });
        const newTokens = response.data;
        token = newTokens.idToken;
        localStorage.setItem("accessToken", newTokens.accessToken);
        localStorage.setItem("idToken", newTokens.idToken);
        // Assuming the token expiration is 1 hour from now
        localStorage.setItem("tokenExpiration", (new Date().getTime() + 3600000).toString());
      } catch (error) {
        console.error("Token refresh failed:", error);
        // Handle the error (e.g., redirect to login)
      }
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
*/

// Login Function
export interface LoginData {
  entity_id?: number;
  role_id?: number;
}

export const login = async ({ entity_id, role_id }: LoginData): Promise<LoginResponse> => {
  console.log("venture api login called ************");
  try {
    const payload: Record<string, number | undefined> = {};
    if (entity_id) payload.entity_id = entity_id;
    if (role_id) payload.role_id = role_id;

    const response = await axiosInstance.post<LoginResponse>(endpoints.auth.login, payload);
    console.log("Login response:", response.data);

    // Add gq_state to localStorage
    if (response.data.state) {
      localStorage.setItem("gq_state", response.data.state);
    }

    await sessionStorage.setItem("loggedInData", JSON.stringify(response.data));
    const temp = await sessionStorage.getItem("loggedInData");
    console.log("temp", JSON.parse(temp)); // Log the temp

    // Dispatch a custom event
    window.dispatchEvent(new Event("loginDataChanged"));
    console.log("dispatch post, pre return response");

    return response.data;
  } catch (error) {
    console.error("Error during login", error);
    throw error;
  }
};

export const signup = async (
  user: RegistrationData,
  referenceKey: string | null = null
): Promise<any> => {
  try {
    console.log("Signup payload", user);
    const response = await axiosInstance.post(endpoints.auth.signup, user);
    return response.data;
  } catch (error) {
    console.error("Error during signup", error);
    throw error;
  }
};

interface GetPresignedUrlResponse {
  url: string;
  token: string;
  [key: string]: any; // You can replace this with more specific keys based on the actual API response structure* review later
}

export const getPresignedUrl = async (
  fileName: string,
  fileType: string,
  purpose: string,
  instance: string
): Promise<GetPresignedUrlResponse> => {
  try {
    const response = await axiosInstance.post<GetPresignedUrlResponse>(
      endpoints.datatransfers.uploadRequest,
      {
        fileName,
        fileType,
        purpose,
        instance,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting presigned URL", error);
    throw error;
  }
};

interface UploadMediaResponse {
  success: boolean;
  [key: string]: any; // You can replace this with more specific keys based on the actual API response structure*
}

export const uploadMedia = async (
  presignedUrl: string,
  file: File,
  token: string,
  key: string,
  entity_id?: number
): Promise<UploadMediaResponse> => {
  try {
    // Upload the file to S3 using the presigned URL
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    // After successful upload, call the validate_upload API
    const response = await axiosInstance.post<UploadMediaResponse>(
      endpoints.datatransfers.validateUpload,
      null,
      {
        params: { token, key, entity_id },
      }
    );

    return response.data; // Return the response from validate_upload
  } catch (error) {
    console.error("Error uploading and validating document", error);
    throw error;
  }
};

interface StepData {
  [key: string]: any; // Assuming stepData can be any type of object
}

interface GQStepPayload {
  step: {
    step_name: string;
    step_data: StepData;
    status: string; // Assuming status is a string; adjust as necessary
  };
}

export const gqSteps = async (
  stepName: string,
  stepData: StepData,
  status: string
): Promise<any> => {
  try {
    const payload: GQStepPayload = {
      step: {
        step_name: stepName,
        step_data: stepData,
        status: status, // Add the status field
      },
    };

    const response = await axiosInstance.patch(endpoints.web.gqSteps, payload);
    await sessionStorage.setItem("loggedInData", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("Error updating GQ step data", error);
    throw error;
  }
};

export const confirmNewTeamMember = async (referenceKey: string): Promise<any> => {
  try {
    const response = await axiosInstance.post(endpoints.auth.teamMemberConfirmation, {
      referenceKey,
    });
    return response.data;
  } catch (error) {
    console.error("Error confirming new team member", error);
    throw error;
  }
};

export const confirmTC = async (): Promise<any> => {
  try {
    // Capture the current time
    const currentTime = new Date().toISOString();

    // Capture browser information using User-Agent Client Hints, if available*
    const browserInfo = {
      userAgent: navigator.userAgent,
      platform: (navigator as any).userAgentData?.platform || navigator.platform,
      brands: (navigator as any).userAgentData?.brands || [],
    };

    // Capture cookies
    const cookies = document.cookie;

    // Capture IP address
    const ipResponse = await fetch("https://api64.ipify.org?format=json");
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;

    // Create payload with additional info
    const payload = {
      tc_confirmation: {
        confirmation_time: currentTime,
        cookies: cookies,
        browser_info: browserInfo,
        ip_address: ipAddress,
      },
    };

    const response = await axiosInstance.patch(endpoints.web.termsConditions, payload);
    return response.data;
  } catch (error) {
    console.error("Error during T&C confirmation", error);
    throw error;
  }
};

export interface NewTeamMemberData {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  user_type: string;
  entity_id: number;
}

export const newTeamMember = async (data: NewTeamMemberData): Promise<any> => {
  try {
    console.log("team member data: ", data);

    const response = await axiosInstance.post(endpoints.auth.newTeamMember, data);
    return response.data;
  } catch (error) {
    console.error("Error adding new team member", error);
    throw error;
  }
};

export const validateBusinessRegistrationNumber = async (registrationNumber: string) => {
  try {
    const response = await axiosInstance.post(
      endpoints.web.validateBusinessRegistration,
      {},
      {
        params: {
          type: "business-registration-number",
          value: registrationNumber,
        },
      }
    );
    // Check if the status is success
    if (response.data.status === "success") {
      return { valid: true, message: response.data.message };
    } else {
      return { valid: false, message: response.data.message || "Invalid registration number." };
    }
  } catch (error) {
    console.error("Error validating registration number:", error);
    return { valid: false, message: "Failed to validate registration number." };
  }
};

interface RetrieveMediaResponse {
  presignedUrl: string;
  key: string;
}

export const retrieveMedia = async (
  mediaType: string,
  entityId?: number,
  include_thumbnail?: boolean
): Promise<RetrieveMediaResponse> => {
  try {
    const response = await axiosInstance.get<RetrieveMediaResponse>(
      endpoints.datatransfers.retrieveMedia,
      {
        params: {
          media_type: mediaType,
          entityId,
          include_thumbnail,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching media", error);
    throw error;
  }
};

interface GetBusinessesResponse {
  all_businesses: Array<{
    entity_name: string;
    one_liner_details: {
      keyOffering: string;
      businessName: string;
      targetMarket: string;
      problemOutline: string;
      problemMaturity: string;
      valueProposition: string;
      problemMaturityOtherInput?: string;
    };
    user_count: number;
    date_added: string;
    logo_url: string | null;
  }>;
  linked_businesses: Array<{
    entity_name: string;
    one_liner_details: {
      keyOffering: string;
      businessName: string;
      targetMarket: string;
      problemOutline: string;
      problemMaturity: string;
      valueProposition: string;
      problemMaturityOtherInput?: string;
    };
    user_count: number;
    date_added: string;
    logo_url: string | null;
  }>;
  invited_businesses: Array<{
    entity_name: string;
    one_liner_details: {
      keyOffering: string;
      businessName: string;
      targetMarket: string;
      problemOutline: string;
      problemMaturity: string;
      valueProposition: string;
      problemMaturityOtherInput?: string;
    };
    user_count: number;
    date_added: string;
    logo_url: string | null;
  }>;
  favourited_entities: Array<{
    entity_name: string;
    one_liner_details: {
      keyOffering: string;
      businessName: string;
      targetMarket: string;
      problemOutline: string;
      problemMaturity: string;
      valueProposition: string;
      problemMaturityOtherInput?: string;
    };
    user_count: number;
    date_added: string;
    logo_url: string | null;
  }>;
}

export const getBusinesses = async (investor_entity_id: number): Promise<GetBusinessesResponse> => {
  try {
    const response = await axiosInstance.get<GetBusinessesResponse>(
      endpoints.connections.getBusinesses,
      {
        params: {
          investor_entity_id: investor_entity_id,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving businesses:", error);
    throw error;
  }
};

export interface BusinessProfileResponse {
  entity_name: string;
  description: string;
  industry: string;
}

export const getBusinessProfile = async (
  requestor_entity_id: number,
  requestee_entity_id: number
): Promise<BusinessProfileResponse> => {
  try {
    const response = await axiosInstance.get<BusinessProfileResponse>(
      endpoints.connections.getBusinessProfile,
      {
        params: {
          requestor_entity_id: requestor_entity_id,
          requestee_entity_id: requestee_entity_id,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving business profile:", error);
    throw error;
  }
};

export interface CreateConnectionRequest {
  message: string;
}

export const createConnectionRequest = async (
  requestor_entity_id: number,
  requestee_entity_id: number
): Promise<CreateConnectionRequest> => {
  try {
    const response = await axiosInstance.post<CreateConnectionRequest>(
      endpoints.connections.createConnectionRequest,
      {},
      {
        params: {
          requestor_entity_id: requestor_entity_id,
          requestee_entity_id: requestee_entity_id,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving business profile:", error);
    throw error;
  }
};

//cickup
interface ClickUpTokenRequest {
  entity_id: number;
  code: string;
  state: string;
}

// Interface for ClickUp Overview response
interface ClickUpOverviewResponse {
  detail: string;
  [key: string]: any;
}

export const getClickUpClientId = async (): Promise<string> => {
  try {
    const response = await axiosInstance.get<{ client_id: string }>(
      endpoints.integrations.clickUp.clientId
    );
    console.log("getClickUpClientId response.data", response.data);
    return response.data.client_id;
  } catch (error) {
    console.error("Error fetching ClickUp client ID", error);
    throw error;
  }
};

// Add ClickUp token
export const addClickUpToken = async (requestData: ClickUpTokenRequest): Promise<any> => {
  try {
    const response = await axiosInstance.post(endpoints.integrations.clickUp.addToken, requestData);
    console.log("addClickUpToken response.data", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding ClickUp token", error);
    throw error;
  }
};

// Get ClickUp overview
export const getClickUpOverview = async (entityId: number): Promise<ClickUpOverviewResponse> => {
  try {
    const response = await axiosInstance.post<ClickUpOverviewResponse>(
      `${endpoints.integrations.clickUp.overview}/${entityId}`,
      {}
    );
    console.log("getClickUpOverview response.data", response.data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      console.error("Folder ID not found in entity's additional_info");
      return { detail: "ID NOT FOUND" };
    }
    console.error("Error fetching ClickUp overview", error);
    throw error;
  }
};

// Fetch team members for a specific entity
export const getTeamMembers = async (entityId: number): Promise<any[]> => {
  try {
    const response = await axiosInstance.get<any[]>(
      `${endpoints.dashboard.teamMembers}/${entityId}/teammembers`
    );
    console.log("Team Members response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching team members:", error);
    throw error;
  }
};

export const initialSetup = async (entityId: number) => {
  try {
    const response = await axiosInstance.post(`/integrations/clickup/initial-setup/${entityId}`);
    console.log("post initialSetup", response.data);
    return response.data;
  } catch (error) {
    console.error("Error during initial setup", error);
    throw error;
  }
};

export const getClickUpTasks = async (entityId: number, listId: string) => {
  try {
    const response = await axiosInstance.get(
      `/integrations/clickup/${entityId}/list/${listId}/tasks`
    );
    return response.data; // Return the tasks data
  } catch (error) {
    console.error("Error fetching ClickUp tasks:", error);
    throw error;
  }
};

export const getClickUpSubtasks = async (entityId: number, listId: string, taskId: string) => {
  try {
    const response = await axiosInstance.get(
      `/integrations/clickup/${entityId}/list/${listId}/task/${taskId}/subtasks`
    );
    return response.data; // Return the subtasks data
  } catch (error) {
    console.error("Error fetching ClickUp subtasks:", error);
    throw error;
  }
};

export const getXeroData = async (
  entityId: number,
  timeframe: string,
  periods: number,
  fromDate: string,
  toDate: string
) => {
  try {
    const response = await axiosInstance.get(`/integrations/xero/${entityId}/data`, {
      params: {
        timeframe,
        periods,
        from_date: fromDate,
        to_date: toDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Xero data:", error);
    throw error;
  }
};

export const authorizeXero = async () => {
  try {
    const response = await axiosInstance.get(`/integrations/xero/authorize`);
    return response.data;
  } catch (error) {
    console.error("Error authorizing Xero:", error);
    throw error;
  }
};

// Service to add Xero Token
export const addXeroToken = async (entityId: number, code: string, state: string) => {
  try {
    const response = await axiosInstance.post("/integrations/xero/add-token", {
      entity_id: entityId,
      code,
      state,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding Xero token:", error);
    throw error;
  }
};

export const refreshXeroToken = async (entityId: number) => {
  try {
    const response = await axiosInstance.post(`/integrations/xero/refresh-token/${entityId}`);
    return response.data;
  } catch (error) {
    console.error("Error refreshing Xero token:", error);
    throw error;
  }
};
/*
export const xeroCallback = async (code: string, state: string) => {
  try {
    const response = await axiosInstance.get("/integrations/xero/callback", {
      params: {
        code,
        state,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during Xero callback:", error);
    throw error;
  }
};
*/
export const getXeroCombinedReport = async (entityId: number) => {
  try {
    const response = await axiosInstance.get(`/integrations/xero/${entityId}/24Months`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Xero combined report:", error);
    throw error;
  }
};

export const getCombinedReport = async (entityId: number) => {
  try {
    const response = await axiosInstance.get(`/integrations/xero/${entityId}/24Months`);
    return response.data;
  } catch (error) {
    console.error("Error fetching the combined report:", error);
    throw error;
  }
};
//clickup
export const updateTaskStatus = async (taskId: string, status: string) => {
  try {
    const response = await axiosInstance.put(`/integrations/clickup/task/${taskId}/status`, null, {
      params: { status },
    });
    console.log("Task Status Updated:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating task status:", error);
    throw error;
  }
};

interface FavouriteEntityParams {
  entity_id: number;
  action?: string;
}

export const favouriteEntity = async ({ entity_id, action }: FavouriteEntityParams) => {
  try {
    const response = await axiosInstance.put(`/connections/user/favourite-entity`, null, {
      params: {
        entity_id,
        action,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error in favouriteEntity: ${error}`);
  }
};
//admin portal//

// Request type (empty since no parameters are required)
export type AdminUserRequest = void;

// Response type
export interface AdminUserResponse {
  email: string;
  first_name: string;
  last_name: string;
  date_added: string;
  user_icon?: string;
  user_id: number;
}

// Fetch admin users
export const getAdminUsers = async (): Promise<AdminUserResponse[]> => {
  try {
    const response = await axiosInstance.get<AdminUserResponse[]>("/admin-portal/users/active");
    console.log("Active Users response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching active users:", error);
    throw error;
  }
};

// Request type
export interface DeleteAdminUserRequest {
  user_id: number; // User ID to be deleted
  entity_id: number; // Entity ID related to the user
}

// Response type
export interface DeleteAdminUserResponse {
  success: boolean;
  message?: string;
  deletedUser?: {
    user_id: number;
    entity_id: number;
    first_name: string;
    last_name: string;
  };
}

// Service to delete an admin user (using DELETE method)
export const deleteAdminUser = async ({
  user_id,
  entity_id,
}: DeleteAdminUserRequest): Promise<DeleteAdminUserResponse> => {
  try {
    const response = await axiosInstance.delete<DeleteAdminUserResponse>(
      "/admin-portal/remove-admin",
      {
        params: {
          user_id, // Pass as an integer directly
          entity_id, // Pass as an integer directly
        },
      }
    );
    console.log("Delete Admin User response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting admin user:", error);
    throw error;
  }
};

// Request type
export interface AddAdminUserRequest {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  user_type: string;
  entity_id: number;
}

// Response type
export interface AddAdminUserResponse {
  success: boolean;
  message?: string;
  createdUser?: {
    user_id: number;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    user_type: string;
  };
}

export const addAdminUser = async (
  userData: AddAdminUserRequest
): Promise<AddAdminUserResponse> => {
  try {
    const response = await axiosInstance.post<AddAdminUserResponse>(
      "/admin-portal/add-administrator",
      userData
    );
    console.log("Add Admin User response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding admin user:", error);
    throw error;
  }
};
export interface UserForAdmin {
  user_id: number;
  user_team_role?: string;
  first_name: string;
  last_name: string;
  email: string;
  date_added: string;
  mobile?: string;
  location?: string;
  entity_deletion_count?: number;
  entity_count?: number;
  //user_team_role?: string;
  entities?: {
    entity_name: string;
    role_name: string;
    user_count: number;
    entity_icon?: string;
  }[];
}

/** KPI RESPONSE TYPE */
export interface AdminKpiStats {
  total_active_users: number;
  average_user_count_per_entity: number;
  user_signup_growth: {
    month: string;
    count: number;
    growth: number;
  }[];
  total_active_entities: number;
  total_entity_connections: number;
  total_investor_users: number;
  total_business_users: number;
}

/** ADMIN USERS SERVICE */
export const getUsersForAdmin = async (): Promise<UserForAdmin[]> => {
  const response = await axiosInstance.get(`/admin-portal/user-management/users`);
  return response.data;
};

/** KPI STATS SERVICE */
export const getAdminKpiStats = async (): Promise<AdminKpiStats> => {
  const response = await axiosInstance.get(`/admin-portal/user-management/stats`);
  return response.data;
};

/** DELETE ADMIN USER */
export const deleteUserForAdmin = async (
  userId: number
): Promise<{ message: string; user_id: number }> => {
  const response = await axiosInstance.delete(`/admin-portal/admin/users/${userId}`);
  return response.data;
};

/** DELINK USER FROM ENTITY SERVICE */
export const delinkUserFromEntity = async (
  userId: number,
  entityId: number
): Promise<{ message: string }> => {
  try {
    const response = await axiosInstance.delete<{ message: string }>(
      `/admin-portal/user-management/users/${userId}/entities/${entityId}/delink`
    );
    console.log("✅ Delink User response:", response.data);
    return response.data;
  } catch (error) {
    console.error(`Error delinking user ${userId} from entity ${entityId}:`, error);
    throw error;
  }
};

//entities
//add pictures
export interface AdminEntity {
  entity_id: number;
  entity_name: string;
  entity_type: string;
  date_added: string; // or "date_created" depending on your API
  business_registration_number?: string;
  website?: string;
  location?: string;
  tax_reference_number?: string;
  users: {
    user_team_role?: string;
    user_role?: string;
    first_name: string;
    last_name: string;
    user_icon?: string;
  }[];
  linked_entities: {
    entity_id: number;
    entity_name: string;
    entity_type: string;
    date_added: string;
  }[];
}

export interface AdminEntitiesResponse {
  entities: AdminEntity[];
  total_count: number;
}

// For the KPI stats:
export interface EntityKpiStats {
  total_users: number;
  average_user_count_per_entity: number;
  entity_growth: {
    month: string;
    count: number;
    growth: number;
  }[];
  total_entities: number;
  total_entity_connections: number;
  total_investor_entities: number;
  total_business_entities: number;
}

// ---------------------------------------
// Fetch Entities
// ---------------------------------------
export const getEntitiesForAdmin = async (
  skip = 0,
  limit = 100
): Promise<AdminEntitiesResponse> => {
  try {
    const response = await axiosInstance.get<AdminEntitiesResponse>(
      "/admin-portal/entity-management/entities/details",
      {
        params: {
          skip, // optional
          limit, // optional
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching admin entities:", error);
    throw error;
  }
};

// ---------------------------------------
// Fetch Entity KPI Stats
// ---------------------------------------
export const getEntityKpiStats = async (): Promise<EntityKpiStats> => {
  try {
    const response = await axiosInstance.get<EntityKpiStats>(
      "/admin-portal/entity-management/stats"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching entity KPI stats:", error);
    throw error;
  }
};

// ---------------------------------------
// Delete an Entity (example)
// ---------------------------------------
export interface DeleteEntityResponse {
  success: boolean;
  message?: string;
  // etc.
}
export const deleteEntityForAdmin = async (entityId: number): Promise<DeleteEntityResponse> => {
  try {
    const response = await axiosInstance.delete<DeleteEntityResponse>(
      `/admin-portal/entity-management/entities/${entityId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting entity:", error);
    throw error;
  }
};

// ---------------------------------------
// Delink an Entity from another, if needed
// /admin-portal/entity-management/entities/{entityId}/linked-entities/{linkedEntityId}
// Example below if your API supports a "delink" route
// ---------------------------------------
export interface DelinkEntityResponse {
  message: string;
  // ...
}
export const delinkEntity = async (
  entityId: number,
  linkedEntityId: number
): Promise<DelinkEntityResponse> => {
  try {
    const response = await axiosInstance.delete<DelinkEntityResponse>(
      `/admin-portal/entity-management/entities/${entityId}/linked-entities/${linkedEntityId}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error delinking entity ${linkedEntityId} from entity ${entityId}`, error);
    throw error;
  }
};

// Request type
export interface UpdateUserRequest {
  first_name: string;
  last_name: string;
  phone_number: string;
  location: string;
}

// Response type
export interface UpdateUserResponse {
  success: boolean;
  message?: string;
  updatedUser?: {
    user_id: number;
    first_name: string;
    last_name: string;
    phone_number: string;
    location: string;
  };
}

export const updateUserDetails = async (
  // userId: number,
  userData: UpdateUserRequest
): Promise<UpdateUserResponse> => {
  try {
    const response = await axiosInstance.put<UpdateUserResponse>(`/user/profile`, userData);
    console.log("Update User Details response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};

// Request type (Entity ID)
export type EntityRequest = {
  entity_id: number;
};

// Response type for entity details
export interface EntityResponse {
  business_name: string | null;
  street_address: string | null;
  business_website: string | null;
  suburb: string | null;
  city: string | null;
  country: string | null;
  zip_code: string | null;
}

// Fetch all entities
export const getEntities = async (entity_id: number): Promise<EntityResponse> => {
  try {
    const response = await axiosInstance.get<EntityResponse>(`/entity/${entity_id}/profile`);
    console.log("Entity response:", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching entity:", error);
    throw error;
  }
};

// Request type
export interface UpdateEntityRequest {
  business_name: string;
  street_address: string;
  business_website: string;
  suburb: string;
  city: string;
  country: string;
  zip_code: string;
}

// Response type
export interface UpdateEntityResponse {
  success: boolean;
  message?: string;
  updatedEntity?: {
    // entity_id?: number;
    business_name: string;
    street_address: string;
    business_website: string;
    suburb: string;
    city: string;
    country: string;
    zip_code: string;
  };
}

export const updateEntityProfile = async (
  entityId: number,
  entityData: UpdateEntityRequest
): Promise<UpdateEntityResponse> => {
  try {
    const response = await axiosInstance.put<UpdateEntityResponse>(
      `/entity/${entityId}/profile`,
      entityData
    );
    console.log("Update Entity Profile response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating entity profile:", error);
    throw error;
  }
};

export interface SoftDeleteAdminUserResponse {
  success: boolean; // Indicates whether the operation was successful
  message?: string; // Optional message that could provide further details
  updatedUser?: {
    user_id: number; // The ID of the user
    first_name: string; // The user's first name
    last_name: string; // The user's last name
    active: boolean; // The updated status of the user (should be false for a soft delete)
    // Other user details may be returned depending on the API response
  };
}

// Service to soft-delete the current user's account (sets active=False)
export const softDeleteUser = async (): Promise<SoftDeleteAdminUserResponse> => {
  try {
    const response = await axiosInstance.delete<SoftDeleteAdminUserResponse>("/user/profile", {});
    console.log("Soft Delete User response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error soft deleting user:", error);
    throw error;
  }
};

//Get Profile of current user

// Response type for user profile
export interface GetUserProfileResponse {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  location: string;
}

// Fetch current user's profile
export const getUserProfile = async (): Promise<GetUserProfileResponse> => {
  try {
    const response = await axiosInstance.get<GetUserProfileResponse>("/user/profile");
    console.log("User Profile response:", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};
//pdf entity data get
// interface for DetailedEntityResponse in types/adminTypes
export const getEntityById = async (entityId: number): Promise<DetailedEntityResponse> => {
  try {
    const { data } = await axiosInstance.get<DetailedEntityResponse>(
      `/admin-portal/entity/${entityId}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching entity details:", error);
    throw error;
  }
};
