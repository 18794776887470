import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { deleteUserForAdmin } from "services/ventureApi";
import { removeUser } from "features/admin/adminSlice";

export const useRemoveUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, error } = useMutation({
    mutationFn: (userId: number) => deleteUserForAdmin(userId),
    onSuccess: (_, userId) => {
      dispatch(removeUser(userId));
      queryClient.invalidateQueries({ queryKey: ["adminUsersData"] });
    },
  });

  return {
    deleteUser: mutate,
    //isDeletingUser: isLoading,
    deleteUserError: error,
  };
};
