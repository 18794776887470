import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserForAdmin, AdminKpiStats } from "services/ventureApi";
import {
  AdminUserResponse,
  DeleteAdminUserResponse,
  AddAdminUserResponse,
  AdminEntity,
  EntityKpiStats,
} from "services/ventureApi";

interface AdminState {
  users: AdminUserResponse[];
  usersForAdmin: UserForAdmin[];
  deleteStatus: "idle" | "loading" | "success" | "error";
  deleteMessage: string;
  adminEntityId: number | null;
  addStatus: "idle" | "loading" | "success" | "error";
  addMessage: string;
  kpiStats: AdminKpiStats;
  //entities
  entitiesForAdmin?: AdminEntity[];
  entityKpiStats?: EntityKpiStats | null;
  totalEntityCount?: number;
}

const initialState: AdminState = {
  users: [],
  usersForAdmin: [],
  kpiStats: null,
  deleteStatus: "idle", // Default state for delete operation
  deleteMessage: "",
  adminEntityId: null,
  addStatus: "idle", // State for tracking add operation
  addMessage: "",
  //entities
  entitiesForAdmin: [],
  entityKpiStats: null,
  totalEntityCount: 0,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminUsersData(state, action: PayloadAction<AdminUserResponse[]>) {
      state.users = action.payload;
    },
    //users
    setUsersForAdminData(state, action: PayloadAction<UserForAdmin[]>) {
      state.usersForAdmin = action.payload;
    },
    setAdminKpiData(state, action: PayloadAction<AdminKpiStats>) {
      state.kpiStats = action.payload;
    },
    removeUser(state, action: PayloadAction<number>) {
      state.users = state.users.filter((user) => user.user_id !== action.payload);
    },
    setDeleteStatus(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
      state.deleteStatus = action.payload;
    },
    setDeleteMessage(state, action: PayloadAction<string>) {
      state.deleteMessage = action.payload;
    },
    setAdminEntityId(state, action: PayloadAction<number | null>) {
      state.adminEntityId = action.payload;
      if (action.payload !== null) {
        // Persist to localStorage
        localStorage.setItem("adminEntityId", action.payload.toString());
      } else {
        // Remove from localStorage if null
        localStorage.removeItem("adminEntityId");
      }
    },
    setAdminEntityError(state) {
      state.adminEntityId = null; // Clear adminEntityId on error
      state.deleteMessage = "Administrator entity not found"; // Optional: You could set an error message
    },
    setAddStatus(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
      state.addStatus = action.payload;
    },
    setAddMessage(state, action: PayloadAction<string>) {
      state.addMessage = action.payload;
    },
    addUserToState(state, action: PayloadAction<AddAdminUserResponse["createdUser"]>) {
      if (action.payload) {
        state.users.push({
          ...action.payload,
          date_added: new Date().toISOString(), // Provide default value
        });
      }
    },
    //entities
    setEntitiesForAdminData(state, action: PayloadAction<AdminEntity[]>) {
      state.entitiesForAdmin = action.payload;
    },
    /** Store entity KPI stats (from /entity-management/stats) */
    setEntityKpiData(state, action: PayloadAction<EntityKpiStats>) {
      state.entityKpiStats = action.payload;
    },
    /** Remove a single entity by ID from our store */
    removeEntity(state, action: PayloadAction<number>) {
      if (!state.entitiesForAdmin) return;
      state.entitiesForAdmin = state.entitiesForAdmin.filter(
        (ent) => ent.entity_id !== action.payload
      );
    },
    setTotalEntityCount(state, action: PayloadAction<number>) {
      state.totalEntityCount = action.payload;
    },
  },
});

export const {
  setAdminUsersData,
  setUsersForAdminData,
  setAdminKpiData,
  removeUser,
  setDeleteStatus,
  setDeleteMessage,
  setAdminEntityId,
  setAdminEntityError,
  setAddMessage,
  setAddStatus,
  addUserToState,
  //entities
  setEntitiesForAdminData,
  setEntityKpiData,
  removeEntity,
  setTotalEntityCount,
} = adminSlice.actions;

export default adminSlice.reducer;
