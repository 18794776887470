import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the structure for the user profile
interface UserProfile {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  location: string;
}

interface UserState {
  isLoggedIn: boolean;
  profileImage?: string; // base64 or URL for the user's profile image
  profile?: UserProfile | null; // User profile details
  loading: boolean; // To manage the loading state
  error: string | null; // To manage any errors
}

const initialState: UserState = {
  isLoggedIn: false,
  profile: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoggedIn(state, action: PayloadAction<{ profileImage: string }>) {
      state.isLoggedIn = true;
      state.profileImage = action.payload.profileImage;
    },
    setUserLoggedOut(state) {
      state.isLoggedIn = false;
      state.profileImage = undefined;
      state.profile = null;
    },
    setUserProfile(state, action: PayloadAction<UserProfile>) {
      state.profile = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setUserLoggedIn, setUserLoggedOut, setUserProfile, setLoading, setError } =
  userSlice.actions;

export default userSlice.reducer;
