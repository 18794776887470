import React from "react";
import MDBox from "components/generic/MDBox";
import KpiCard from "./KpiCard";

interface KpiSectionProps {
  data?: any;
  isLoading?: boolean;
}

const KpiSection: React.FC<KpiSectionProps> = ({ data, isLoading }) => {
  if (isLoading) return <MDBox p={2}>Loading KPI data...</MDBox>;
  return (
    <MDBox display="flex" justifyContent="space-between" p={1}>
      <KpiCard title="Total Users" value={data?.total_active_users ?? 0} />
      <KpiCard
        title="Avg. Users per Entity"
        value={Number((data?.average_user_count_per_entity ?? 0).toFixed(0))}
      />
      <KpiCard
        title="User Growth (Previous Month)"
        value={`${data?.user_signup_growth[0]?.growth ?? 0}%`}
      />
      <KpiCard title="Total Entities" value={data?.total_active_entities ?? 0} />
      <KpiCard title="Total Entity Connections" value={data?.total_entity_connections ?? 0} />
      <KpiCard title="Total Investor Users" value={data?.total_investor_users ?? 0} />
      <KpiCard title="Total Startup Users" value={data?.total_business_users ?? 0} isLast />
    </MDBox>
  );
};

export default KpiSection;
