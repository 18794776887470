import React, { useState, ChangeEvent, useMemo } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import img from "../../../assets/images/uploadimages/1.png";
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
import MDTypography from "components/generic/MDTypography";
import { Cancel } from "@mui/icons-material";
import MDProgress from "components/generic/MDProgress";
import { getPresignedUrl, uploadMedia } from "services/ventureApi";
import MDDropzone from "components/generic/MDDropzone";

interface UploadComponentProps {
  onClose: () => void;
  btndata: (value: boolean) => void;
  purpose: "user_profile" | "business_logo";
  entityId?: number;
}

const UploadComponent: React.FC<UploadComponentProps> = ({
  onClose,
  btndata,
  purpose,
  entityId,
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [base64Image, setBase64Image] = useState<string>("");

  const convertToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      setBase64Image(base64String);
      // localStorage.setItem("profileImage", base64String); // Save to localStorage
    };
    reader.readAsDataURL(file);
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setFiles(selectedFiles);

      // Convert the first file to Base64 (you can handle multiple if needed)
      convertToBase64(selectedFiles[0]);
    }
  };
  const handleUpload = async () => {
    setUploading(true);
    setError(false);
    try {
      for (const file of files) {
        // Get presigned URL from the backend
        const presignedData = await getPresignedUrl(
          file.name,
          file.type,
          purpose,
          process.env.REACT_APP_INSTANCE!
        );
        const { presignedUrl, token, key } = presignedData;

        // Upload file to presigned URL
        await uploadMedia(presignedUrl, file, token, key, entityId);
      }

      // After successful upload, set uploading state to false and close the component
      setUploading(false);
      btndata(false);
      onClose();
    } catch (err) {
      console.error("Error uploading file:", err);
      setError(true);
      setUploading(false);
    }
  };
  /*
  const handleUpload = async () => {
    setUploading(true);
    setError(false);
    try {
      // Loop through selected files and upload each one
      for (const file of files) {
        /*
        const logoImage = document.getElementById("bannerImg") as HTMLImageElement | null;
        const imageData = getBase64Image(logoImage);
        
        localStorage.setItem("imgData", imageData);
        
        // Get presigned URL from the backend
        const presignedData = await getPresignedUrl(
          file.name,
          file.type,
          "user-profile-image",
          process.env.REACT_APP_INSTANCE!
        );
        const { presignedUrl, token } = presignedData;
        console.log("presignedData", presignedData);

        const docsUploaded = await uploadMedia(presignedUrl, file, token);
        console.log("docsUploaded", docsUploaded);

        // Set uploading state to false and close the component after successful upload
        setUploading(false);
        btndata(false);
        onClose();
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      setError(true);
      setUploading(false);
    }
  };
*/
  return (
    <Card sx={{ width: { xs: "90%", sm: "90%", lg: "500px" } }}>
      <MDBox
        sx={{
          py: "30px",
          px: "52px",
          height: "auto",
          maxHeight: "90vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography textAlign="center" variant="h4" gutterBottom>
          Upload
        </Typography>
        {useMemo(
          () => (
            <MDDropzone
              options={{
                url: "/file-upload", // This URL can be ignored because you handle uploads manually
                autoProcessQueue: false, // Prevent auto-upload so you can handle it manually
                addRemoveLinks: true,
                init: function () {
                  this.on("addedfile", (file: File) => {
                    setFiles((prevFiles) => [...prevFiles, file]); // Add files to state when uploaded
                    convertToBase64(file);
                  });
                  this.on("removedfile", (file: File) => {
                    setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name)); // Remove files from state
                  });
                },
              }}
            />
          ),
          []
        )}

        {/*
        <MDBox
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            py: 5,
            justifyContent: "center",
            alignItems: "center",
            border: "1px dashed #424141",
            px: 2,
          }}
        >
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <MDBox sx={{ alignContent: "center" }}>
              <img src={img} alt="Upload Icon" style={{ width: "69px", height: "60px" }} />
            </MDBox>
            <Typography sx={{ fontSize: "14px" }} variant="body1" gutterBottom>
              Drag & drop files or{" "}
              <Typography
                variant="h6"
                component="span"
                color="info"
                sx={{ textDecoration: "underline", color: "#85B526", cursor: "pointer" }}
              >
                Browse
              </Typography>{" "}
              
            </Typography>
            <Typography sx={{ fontSize: "12px" }} variant="body1" gutterBottom>
              Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
            </Typography>
          </label>
        </MDBox>
      */}
        {uploading && (
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap={1}
            py={2}
            sx={{ width: "100%" }}
          >
            <MDTypography variant="body1" fontSize="14px" color="text">
              Uploading - {files.length}/{files.length} files
            </MDTypography>
            <MDBox
              sx={{
                width: "100%",
                borderRadius: "3px",
                border: "1px solid",
                borderColor: "#C7CCD0",
              }}
            >
              {files.map((file, index) => (
                <div key={index}>
                  <MDBox display="flex" alignItems="center" justifyContent="space-between" p={1}>
                    <MDTypography variant="body1" fontSize="14px" color="dark">
                      {file.name}
                    </MDTypography>
                    <Cancel
                      fontSize="small"
                      sx={{ cursor: "pointer", color: "#C7CCD0", ":hover": { color: "red" } }}
                    />
                  </MDBox>
                  <MDProgress variant="gradient" color="info" value={uploading ? 50 : 0} />
                </div>
              ))}
            </MDBox>
            {error && (
              <MDBox sx={{ width: "100%" }}>
                <MDBox
                  sx={{
                    width: "100%",
                    borderRadius: "3px",
                    border: "1px solid",
                    borderColor: "red",
                  }}
                >
                  {files.map((file, index) => (
                    <div key={index}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={1}
                      >
                        <MDTypography variant="body1" fontSize="14px" color="dark">
                          {file.name}
                        </MDTypography>
                        <Cancel
                          fontSize="small"
                          sx={{ cursor: "pointer", color: "red", ":hover": { color: "red" } }}
                        />
                      </MDBox>
                      <MDTypography variant="body1" fontSize="9px" color="primary">
                        This document is not supported, please delete and upload another file.
                      </MDTypography>
                    </div>
                  ))}
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        )}
        <MDButton
          variant="gradient"
          color="info"
          onClick={handleUpload}
          fullWidth
          sx={{ marginTop: "40px" }}
        >
          Upload Files
        </MDButton>
      </MDBox>
    </Card>
  );
};

export default UploadComponent;
