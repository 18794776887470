import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityResponse, UpdateEntityRequest, UpdateEntityResponse } from "services/ventureApi";

// Define the state shape for entities
interface EntityState {
  entity: EntityResponse | null;
  loading: boolean;
  updating: boolean; // New state for tracking update requests
  error: string | null;
  success: boolean; // New state for success/failure of update
}

// Initial state
const initialState: EntityState = {
  entity: null,
  loading: false,
  updating: false, // Start with no update in progress
  error: null,
  success: false, // No successful update by default
};

// Redux slice for managing entities (fetching and updating)
const entitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    // Fetching entity
    fetchEntityStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchEntitySuccess: (state, action: PayloadAction<EntityResponse>) => {
      state.entity = action.payload;
      state.loading = false;
    },
    fetchEntityFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    // Updating entity
    updateEntityStart: (state) => {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateEntitySuccess: (state, action: PayloadAction<UpdateEntityResponse>) => {
      state.entity = action.payload.updatedEntity;
      state.updating = false;
      state.success = true;
    },
    updateEntityFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.updating = false;
      state.success = false;
    },

    // Set entity details directly
    setEntityDetails: (state, action: PayloadAction<EntityState["entity"]>) => {
      state.entity = action.payload;
    },

    // Generic loading/error handling
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchEntityStart,
  fetchEntitySuccess,
  fetchEntityFailure,
  updateEntityStart,
  updateEntitySuccess,
  updateEntityFailure,
  setEntityDetails,
  setLoading,
  setError,
} = entitySlice.actions;

export default entitySlice.reducer;
