import { Card, CardContent, CardMedia, IconButton, Box, Typography, Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline"; // Icon example
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { RootState } from "app/store";
import { useSelector } from "react-redux";

type AdminCardProps = {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  image: string;
  selectedUserId: number;
  handleDeleteClick: (itemName: string, userId: number) => void;
  confirmDelete: (userId: number, adminEntityId: number) => void;
};

function AdminCard({
  firstName,
  lastName,
  role,
  email,
  image,
  selectedUserId,
  handleDeleteClick,
  confirmDelete,
}: AdminCardProps) {
  const adminEntityId = useSelector((state: RootState) => state.admin.adminEntityId);

  return (
    <Card
      sx={{
        // width: "100%",
        // maxWidth: "400px",
        background: "transparent",
        color: "#fff",
        boxShadow: "none",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "top", // Align content horizontally
        marginLeft: "10px",
        height: "fit-content",
      }}
    >
      {/* Image/Icon Block */}
      <CardMedia
        component="img"
        image={image}
        alt={`${firstName} ${lastName}`}
        sx={{
          width: 60, // Adjust icon/image width
          height: 60,
          borderRadius: "8px", // Makes it circular
          margin: 0,
          mt: "2px",
        }}
      />
      {/* Title + Description Block */}
      <CardContent sx={{ flexGrow: 1, paddingTop: 0, marginTop: 0, pl: "8px" }}>
        <MDBox display={"flex"} alignItems={"center"}>
          <Typography
            sx={{ color: "#4C4E52", fontSize: "14px", fontWeight: 700, fontFamily: "Roboto" }}
          >
            {`${firstName} ${lastName}`}
          </Typography>
          {/* <VerifiedOutlinedIcon sx={{ paddingLeft: "5px", color: "#4C4E52 !important" }} /> */}
        </MDBox>
        <Typography
          sx={{ color: "#4C4E52", fontSize: "12px", fontWeight: 400, fontFamily: "Roboto" }}
        >
          {role}
        </Typography>
        <Typography
          sx={{ color: "#4C4E52", fontSize: "12px", fontWeight: 400, fontFamily: "Roboto" }}
        >
          {email}
        </Typography>
      </CardContent>

      {/* Follow Button Block */}
      <MDTypography
        sx={{
          color: "#F44334",
          fontSize: "12px",
          fontWeight: 700,
          cursor: "pointer",
          marginTop: "20px",
          marginRight: "15%",
        }}
        onClick={() => {
          console.log("firstName", firstName, "userId", selectedUserId);
          handleDeleteClick(firstName, selectedUserId);
        }}
      >
        REMOVE
      </MDTypography>
    </Card>
  );
}

export default AdminCard;
