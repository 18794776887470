import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import CircularProgress from "@mui/material/CircularProgress";

import {
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import ViewListIcon from "@mui/icons-material/ViewList";
import DeleteIcon from "@mui/icons-material/Delete";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonIcon from "@mui/icons-material/Person";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";

import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import AdminCard from "./components/AdminCard";
import DeleteAdminModal from "./components/DeleteAdminModal";
import AddAdminModal from "./components/AddAdminModal";
import backgroundImage from "assets/images/bg-profile.jpeg";
import EditProfilePic from "assets/images/startup-profile/edit-dp.png";
import AdminNavbar from "./components/AdminNavbar";

//hooks
import { useAdminUsersData } from "hooks/admin/useAdminUsersData";
import { useDeleteAdminUser } from "hooks/admin/useDeleteAdminUser";
import { useAuth } from "context/AuthContext";

import { setAdminEntityId, setAdminEntityError } from "features/admin/adminSlice";
import { useDispatch } from "react-redux";

type AdminLayoutProps = {
  setActiveTab: (state: number) => void;
  activeTab: number;
};

function AdminLayout({ setActiveTab, activeTab }: AdminLayoutProps): JSX.Element {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [isTableView, setIsTableView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" | null }>({
    key: "",
    direction: "asc",
  });

  const adminEntityId = useSelector((state: RootState) => state.admin.adminEntityId);
  //hooks called
  const { handleDeleteAdminUser } = useDeleteAdminUser();
  const { refetch } = useAdminUsersData();
  const { loginResponse } = useAuth();
  console.log("Logindetails from authcontext", loginResponse);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedAdminEntityId = localStorage.getItem("adminEntityId");

    if (storedAdminEntityId) {
      dispatch(setAdminEntityId(parseInt(storedAdminEntityId)));
    }
  }, [dispatch]); // Make sure dispatch is included in the dependency array

  console.log("Rootstate selection", adminEntityId);
  // // Dummy data generation
  // const generateDummyRows = (count: number) => {
  //   return Array.from({ length: count }, (_, index) => ({
  //     info: `User ${index + 1} Name & Surname`,
  //     tag: index % 2 === 0 ? "Platform Admin" : "Moderator",
  //     emailAddress: `user${index + 1}@example.com`,
  //     dateAdded: `2025-01-${String(index + 1).padStart(2, "0")}`,
  //     image: "/image-profilepic.jpg",
  //   }));
  // };

  // const tableData = {
  //   rows: generateDummyRows(40),
  // };

  // Access entity data from loginResponse
  const entity = loginResponse.entity; // The "entity" object from the login response
  if (entity && entity.entity_name === "Administrator") {
    dispatch(setAdminEntityId(entity.entity_id)); // Dispatch adminEntityId if it's an "Administrator"
  } else {
    dispatch(setAdminEntityError()); // Dispatch error action if no "Administrator" entity found
  }

  const { adminUsersData, isAdminUsersLoading, adminUsersError } = useAdminUsersData();

  if (isAdminUsersLoading) return <p>Loading users...</p>;
  if (adminUsersError) return <p>Error loading users: {adminUsersError.message}</p>;
  console.log("adminUsersData", adminUsersData);
  // Filter rows based on search query
  const filteredRows = adminUsersData.filter(
    (row) =>
      row.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.date_added.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDeleteClick = (itemName: string, userId: number) => {
    setSelectedItemName(itemName); // Store the name of the item to be deleted
    setSelectedUserId(userId);
    setShowDeleteModal(true); // Show the delete modal
  };

  const confirmDelete = async (userId: number, adminEntityId: number) => {
    try {
      console.log("Attempting to delete user:", userId);
      await handleDeleteAdminUser(userId, adminEntityId); // Await the delete operation

      // Ensure refetch is called after deletion is complete
      console.log("Deleting user complete, calling refetch...");
      refetch(); // Wait until deletion is complete before refetching data
      console.log("Refetch called");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleAddClick = () => {
    setSelectedItemName(null); // Store the name of the item to be deleted
    setShowAddModal(true); // Show the delete modal
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
    setShowAddModal(false);
    setSelectedItemName(null);
  };

  const handleSort = (key: string) => {
    const direction =
      sortConfig.key === key ? (sortConfig.direction === "asc" ? "desc" : "asc") : "asc"; // Default to ascending if a new column is clicked
    setSortConfig({ key, direction });
  };

  const sortedRows = [...filteredRows].sort((a: any, b: any) => {
    if (!sortConfig.key) return 0;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  const removeTimeFromDate = (dateString: string): string => {
    const date = new Date(dateString); // Convert string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
  };

  const customFontStyle = {
    color: "#1C1C1C",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
  };

  const customHeaderStyle = {
    color: "#1C1C1C",
    fontSize: "12px",
    fontFamily: "Titillium Web,sans-serif",
    fontWeight: 700,
  };

  return (
    <DashboardLayout>
      <MDBox mb={6}>
        <AdminNavbar />
      </MDBox>
      <MDBox
        sx={{
          // position: "fixed",
          width: "99%",
          borderRadius: "12px",
          backgroundColor: "#FFFFFF",
          p: "20px",
        }}
      >
        {/* Header Section */}
        <MDBox
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={"20px"}
        >
          <MDTypography
            sx={{
              paddingTop: "10px",
              color: "#4C4E52",
              marginLeft: 4,
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
            Administrators
          </MDTypography>

          <Button
            variant="contained"
            sx={{
              background: "#202020",
              color: "white",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "13px",
              fontWeight: 700,
              height: "30px",
              py: "8px",
              px: "16px",
              mt: "5px",
              mr: "20px",
              boxShadow: "0px 6.472px 8.629px 0px rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#202020",
              },
            }}
            onClick={handleAddClick}
          >
            + ADD NEW ADMIN
          </Button>
        </MDBox>

        {/* Search Input */}
        <MDBox sx={{ px: 4, width: "100%", mb: "24px" }} display={"flex"} alignItems="center">
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              sx: {
                color: "#1C1C1C !important",
                "& .MuiInputBase-input": {
                  color: "#1C1C1C !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#1C1C1C !important",
                  opacity: 1,
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#1C1C1C !important",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#7B809A !important",
                },
                "&:hover fieldset": {
                  borderColor: "#7B809A !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#7B809A !important",
                },
              },
            }}
            placeholder="Search here"
          />

          {isTableView ? (
            <GridViewIcon
              sx={{
                color: "#000000",
                cursor: "pointer",
                border: "solid 1px #000000",
                borderRadius: "6.8px",
                fontSize: "16px",
                padding: "4px",
                width: "40px",
                height: "40px",
                mt: "6px",
                marginLeft: "16px",
              }}
              onClick={() => setIsTableView((prev) => !prev)}
            />
          ) : (
            <ViewListIcon
              sx={{
                color: "#000000",
                cursor: "pointer",
                border: "solid 1px #000000",
                borderRadius: "6.8px",
                fontSize: "16px",
                padding: "4px",
                width: "40px",
                height: "40px",
                mt: "6px",
                marginLeft: "16px",
              }}
              onClick={() => setIsTableView((prev) => !prev)}
            />
          )}
        </MDBox>

        {/* View Section */}

        {isTableView ? (
          <TableContainer
            component={Paper}
            sx={{
              mt: 3,
              mx: 2,
              maxHeight: {
                xs: "40vh", // 50% of viewport height for extra small screens
                sm: "50vh", // 60% for small screens (mobile devices)
                md: "65vh", // 70% for medium and larger screens
              },
              overflowY: "auto",
              boxShadow: 0,
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Color of the scrollbar thumb
                borderRadius: "4px", // Rounded corners for the scrollbar thumb
                "&:hover": {
                  backgroundColor: "#555", // Darker color when hovered
                },
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1", // Track color
              },
            }}
          >
            <Table>
              <thead>
                <TableRow>
                  <TableCell align="left" onClick={() => handleSort("info")}>
                    <MDTypography
                      component="span"
                      sx={{
                        ...customHeaderStyle,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Name
                      <UnfoldMoreIcon
                        sx={{
                          marginLeft: "30%",
                          color: "#1C1C1C",
                          width: "20px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </MDTypography>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleSort("info")}>
                    <MDTypography
                      component="span"
                      sx={{
                        ...customHeaderStyle,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Surname
                      <UnfoldMoreIcon
                        sx={{
                          marginLeft: "30%",
                          color: "#1C1C1C",
                          width: "20px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </MDTypography>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleSort("email")}>
                    <MDTypography
                      component="span"
                      sx={{ ...customHeaderStyle, display: "flex", alignItems: "center" }}
                    >
                      Email
                      <UnfoldMoreIcon
                        sx={{
                          marginLeft: "30%",
                          color: "#1C1C1C",
                          width: "20px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </MDTypography>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleSort("tag")}>
                    <MDTypography
                      component="span"
                      sx={{ ...customHeaderStyle, display: "flex", alignItems: "center" }}
                    >
                      Role
                      <UnfoldMoreIcon
                        sx={{
                          marginLeft: "30%",
                          color: "#1C1C1C",
                          width: "20px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </MDTypography>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleSort("dateAdded")}>
                    <MDTypography
                      component="span"
                      sx={{ ...customHeaderStyle, display: "flex", alignItems: "center" }}
                    >
                      Date Added
                      <UnfoldMoreIcon
                        sx={{
                          marginLeft: "30%",
                          color: "#1C1C1C",
                          width: "20px",
                          height: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </MDTypography>
                  </TableCell>
                  <TableCell align="center">
                    <MDTypography component="span" sx={customHeaderStyle}>
                      Actions
                    </MDTypography>
                  </TableCell>
                </TableRow>
              </thead>
              <TableBody>
                {sortedRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={customFontStyle}>
                      {row.first_name}
                    </TableCell>
                    <TableCell align="left" sx={customFontStyle}>
                      {row.last_name}
                    </TableCell>
                    <TableCell align="left" sx={customFontStyle}>
                      {row.email}
                    </TableCell>
                    <TableCell align="left" sx={customFontStyle}>
                      {"Platform Admin"}
                    </TableCell>
                    <TableCell align="left" sx={customFontStyle}>
                      {removeTimeFromDate(row.date_added)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleDeleteClick(row.first_name, row.user_id)}>
                        <DeleteIcon sx={{ color: "#000000" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MDBox
            mx={2}
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
            sx={{
              maxHeight: {
                xs: "40vh", // 50% of viewport height for extra small screens
                sm: "50vh", // 60% for small screens (mobile devices)
                md: "65vh", // 70% for medium and larger screens
              },
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Color of the scrollbar thumb
                borderRadius: "4px", // Rounded corners for the scrollbar thumb
                "&:hover": {
                  backgroundColor: "#555", // Darker color when hovered
                },
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1", // Track color
              },
            }}
          >
            {filteredRows.map((row, index) => (
              <AdminCard
                key={index}
                firstName={row.first_name}
                lastName={row.last_name}
                role={"Platform Admin"}
                email={row.email}
                image={row.user_icon || EditProfilePic}
                selectedUserId={row.user_id}
                handleDeleteClick={handleDeleteClick}
                confirmDelete={confirmDelete}
              />
            ))}
          </MDBox>
        )}
        {showDeleteModal && (
          <DeleteAdminModal
            selectedUserId={selectedUserId}
            confirmDelete={confirmDelete}
            onClose={handleModalClose}
            itemName={selectedItemName}
          />
        )}
        {showAddModal && <AddAdminModal onClose={handleModalClose} itemName={selectedItemName} />}
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminLayout;
