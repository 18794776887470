import React, { useState } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
import { useFavorites } from "context/FavoritesContext";
import { Business } from "types/businessTypes";
import logo_placeholder from "assets/logo_placeholder.png";

// Declaring prop types for BusinessPortfolioCard
/*
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  image: string;
  title: string;
  dateTime?: string;
  oneLiner: string;
  participantsCount: number;
  establishmentDate: string;
  members?: string[];
  isFavorited?: boolean; // To track if the business is marked as favorite
  onFavoriteToggle?: (favorited: boolean) => void; // External function to handle favorite toggle if needed
  [key: string]: any;
}
*/
interface BusinessPortfolioCardProps {
  business: Business;
  canBeFavorited: boolean;
  isFavorited?: boolean;
  onFavoriteToggle?: () => void;
  loading?: boolean;
  onAvatarClick?: (business: Business) => void;
}
const constructOneLiner = (details: any): string => {
  const { businessName, problemOutline, keyOffering, targetMarket, valueProposition } = details;

  if (!businessName || !problemOutline || !keyOffering || !targetMarket || !valueProposition) {
    return "No description available";
  }

  return `${businessName} is tackling the ${problemOutline} by offering ${keyOffering} which enables ${targetMarket} to ${valueProposition}.`;
};

// Custom styles for BusinessPortfolioCard
const BusinessPortfolioCard: React.FC<BusinessPortfolioCardProps> = ({
  business,
  canBeFavorited,
  isFavorited = false,
  onFavoriteToggle,
  loading = false,
  onAvatarClick,
}) => {
  //const [favorited, setFavorited] = useState(isFavorited);
  // const { isFavorited, toggleFavorite } = useFavorites();
  // const favorited = isFavorited(business);
  /*
  const handleFavoriteClick = () => {
    toggleFavorite(business);
  };
  */
  const [avatarSrc, setAvatarSrc] = useState<string>(business.logo_url || logo_placeholder);
  console.log("logo", business.logo_url);
  const handleFavoriteClick = () => {
    if (onFavoriteToggle && !loading) {
      onFavoriteToggle();
    }
  };

  const oneLinerText =
    business.one_liner_details && Object.keys(business.one_liner_details).length > 0
      ? constructOneLiner(business.one_liner_details)
      : "No description available";

  /*
  const renderMembers = members?.map((member, key) => {
    const memberKey = `member-${key}`;
*/
  /*
    return (
      <MDAvatar
        key={memberKey}
        src={member}
        alt="member profile"
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          "&:not(:first-of-type)": {
            ml: -1.25,
          },
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    );
  });
  */

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={avatarSrc}
            alt={business.entity_name}
            size="xl"
            variant="rounded"
            bgColor="dark"
            sx={{
              p: 1,
              mt: -6,
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
              cursor: "pointer", // Indicates clickability
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.05)", // Slight zoom on hover
              },
            }}
            onClick={() => onAvatarClick && onAvatarClick(business)}
          />
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              {business.entity_name}
            </MDTypography>
            {/* {members?.length ? <MDBox display="flex">{renderMembers}</MDBox> : null}*/}
          </MDBox>

          {/* Favorite button toggle */}
          {canBeFavorited && (
            <MDTypography
              onClick={handleFavoriteClick}
              sx={{
                ml: "auto",
                mt: -1,
                alignSelf: "flex-start",
                py: 1.25,
                color: isFavorited ? "primary.main" : "secondary",
                cursor: loading ? "not-allowed" : "pointer",
                transition: "color 0.3s ease", // Smooth color transition
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>{isFavorited ? "star" : "star_outline"}</Icon>
            </MDTypography>
          )}
        </MDBox>

        {/* One-liner description */}
        <MDBox my={2} lineHeight={1}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {oneLinerText}
          </MDTypography>
        </MDBox>

        <Divider />

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          {/* Participants count */}
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography variant="button" fontWeight="medium">
              {business.user_count || 0}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="secondary">
              Participants
            </MDTypography>
          </MDBox>

          {/* Establishment Date */}
          {business.date_added && (
            <MDBox display="flex" flexDirection="column" lineHeight={0}>
              <MDTypography variant="button" fontWeight="medium">
                {new Date(business.date_added).toLocaleDateString("en-GB")}
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="secondary">
                Establishment Date
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
};

// Declaring default props for BusinessPortfolioCard
/*
BusinessPortfolioCard.defaultProps = {
  color: "dark",
};
*/

export default BusinessPortfolioCard;
