import React from "react";
import MDBox from "components/generic/MDBox";
import KpiCard from "./KpiCard"; // or reuse from users

import { EntityKpiStats } from "services/ventureApi";

interface KpiSectionProps {
  data?: EntityKpiStats;
  isLoading?: boolean;
}

const KpiSection: React.FC<KpiSectionProps> = ({ data, isLoading }) => {
  if (isLoading) {
    return <MDBox p={2}>Loading KPI data...</MDBox>;
  }
  if (!data) return null;

  return (
    <MDBox display="flex" justifyContent="space-between" p={1}>
      <KpiCard title="Total Entities" value={data.total_entities} />
      <KpiCard title="Total Users (in Entities)" value={data.total_users} />
      <KpiCard
        title="Avg. User Count / Entity"
        value={Number((data.average_user_count_per_entity ?? 0).toFixed(0))}
      />
      <KpiCard
        title="Entity Growth (Last Month)"
        value={`${data.entity_growth[0]?.growth ?? 0}%`}
      />
      <KpiCard title="Total Connections" value={data.total_entity_connections} />
      <KpiCard title="Investor Entities" value={data.total_investor_entities} />
      <KpiCard title="Business Entities" value={data.total_business_entities} isLast />
    </MDBox>
  );
};

export default KpiSection;
