import { useMutation, useQueryClient } from "@tanstack/react-query";
import { delinkUserFromEntity } from "services/ventureApi";

export const useDelinkUserForAdmin = () => {
  const queryClient = useQueryClient();

  const { mutate, error } = useMutation({
    mutationFn: ({ userId, entityId }: { userId: number; entityId: number }) =>
      delinkUserFromEntity(userId, entityId),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({ queryKey: ["adminUsersData"] });
      console.log(`✅ Successfully delinked user ${userId}`);
    },
  });

  return {
    delinkUser: mutate,
    // isDelinking: isLoading,
    delinkError: error,
  };
};
