import { useDispatch } from "react-redux";
import {
  setLoading,
  setEntityDetails,
  setError,
  updateEntityStart,
  updateEntityFailure,
  updateEntitySuccess,
} from "features/entity/entitySlice";
import {
  updateEntityProfile,
  UpdateEntityRequest,
  UpdateEntityResponse,
} from "services/ventureApi";
import { useState } from "react";

export const useUpdateEntityProfile = () => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const updateEntity = async (
    entityId: number,
    entityData: UpdateEntityRequest
  ): Promise<UpdateEntityResponse> => {
    try {
      dispatch(updateEntityStart()); // Start the update process
      setIsUpdating(true);
      dispatch(setLoading(true));
      console.log("Response in hook", entityData, entityId);
      const response = await updateEntityProfile(entityId, entityData);

      if (response.success) {
        dispatch(updateEntitySuccess(response)); // Dispatch on success
        dispatch(setEntityDetails(response.updatedEntity || null)); // Optionally update the entity in Redux
      } else {
        dispatch(updateEntityFailure(response.message || "Failed to update entity details"));
      }

      setIsUpdating(false);
      dispatch(setLoading(false));

      return response;
    } catch (error) {
      dispatch(updateEntityFailure("An error occurred while updating entity details"));
      dispatch(setLoading(false));
      setIsUpdating(false);
      throw error;
    }
  };

  return { updateEntity, isUpdating };
};
