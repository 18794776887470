/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "./context/AuthContext";
import { SkipStepProvider } from "./context/skipStepContext";
import { FavoritesProvider } from "context/FavoritesContext";
// Redux
import { Provider } from "react-redux";
import { store } from "./app/store";
// React Query
import {
  QueryClient,
  QueryClientProvider,
  onlineManager,
  focusManager,
} from "@tanstack/react-query";
//persistance
/*
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import localforage from "localforage";
*/
// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FavoritesProvider>
            <SkipStepProvider>
              <MaterialUIControllerProvider>
                <App />
              </MaterialUIControllerProvider>
            </SkipStepProvider>
          </FavoritesProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
);
