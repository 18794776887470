import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { TextField, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import FormField from "../../components/FormField";
import selectData from "../data/selectData";
import { useAuth } from "context/AuthContext";
import { useFetchEntity } from "hooks/entity/useFetchEntity";
import MDButton from "components/generic/MDButton";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { useUpdateEntityProfile } from "hooks/entity/useUpdateEntityProfile";

const BasicInfo: React.FC = () => {
  const { loginResponse } = useAuth();
  const [formData, setFormData] = useState({
    businessName: "",
    address: "",
    website: "",
    suburb: "",
    city: "",
    country: "",
    zip: "",
  });
  const [entityId, setEntityId] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [activeEntityId, setActiveEntityId] = useState<number>();

  const { entity, loading, error, fetchEntity } = useFetchEntity();
  const { updateEntity, isUpdating } = useUpdateEntityProfile();

  useEffect(() => {
    setEntityId(loginResponse.entity.entity_id);
  }, []);

  // Fetch entity data when component mounts
  useEffect(() => {
    if (entityId) {
      fetchEntity(entityId);
    }
  }, [entityId]); // Only fetch data when entityId changes

  // Update formData when entity is fetched, but only if the entity data changes
  useEffect(() => {
    if (entity) {
      setFormData((prevData) => {
        // Check if any field has changed before updating
        if (
          prevData.businessName !== entity.business_name ||
          prevData.address !== entity.street_address ||
          prevData.website !== entity.business_website ||
          prevData.suburb !== entity.suburb ||
          prevData.city !== entity.city ||
          prevData.country !== entity.country ||
          prevData.zip !== entity.zip_code
        ) {
          return {
            businessName: entity.business_name || "",
            address: entity.street_address || "",
            website: entity.business_website || "",
            suburb: entity.suburb || "",
            city: entity.city || "",
            country: entity.country || "",
            zip: entity.zip_code || "",
          };
        }
        return prevData; // If nothing has changed, don't update the state
      });
    }
  }, [entity]);

  useEffect(() => {
    setActiveEntityId(loginResponse.entity.entity_id);
  }, []);

  const handleChange = (field: string, value: string | string[]) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleCancel = () => {
    setIsEditing(false);
    if (entity) {
      // Reset form fields to current user profile values
      setFormData({
        businessName: entity.business_name || "",
        address: entity.street_address || "",
        website: entity.business_website || "",
        suburb: entity.suburb || "",
        city: entity.city || "",
        country: entity.country || "",
        zip: entity.zip_code || "",
      });
    }
  };

  const handleSave = async () => {
    if (!activeEntityId) {
      console.warn("No active user ID available.");
      return;
    }

    try {
      const entityData = {
        business_name: formData.businessName,
        street_address: formData.address,
        business_website: formData.website,
        suburb: formData.suburb,
        city: formData.city,
        country: formData.country,
        zip_code: formData.zip,
      };
      console.log("Formdata", entityData, activeEntityId);
      await updateEntity(activeEntityId, entityData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const formFieldStyles = {
    sx: {
      backgroundColor: "white !important",
      "& .MuiInputBase-root": {
        backgroundColor: "white !important",
        color: isEditing ? "#7B809A !important" : "#C7CCD0 !important",
        fontSize: "14px !important",
        fontFamily: "Roboto !important",
        fontWeight: 400,
      },
      "& .MuiInputLabel-root": {
        color: "#7B809A !important",
        fontSize: "18px !important",
        fontFamily: "Roboto !important",
        fontWeight: 400,
      },
    },
  };

  return (
    <Card id="entity-details" sx={{ overflow: "visible" }}>
      <MDBox p={3} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <MDTypography variant="h5" sx={{ color: "#344767" }}>
          Entity Details
        </MDTypography>
        <MDBox mt={3}>
          {!isEditing ? (
            <MDButton
              variant="contained"
              onClick={() => setIsEditing(true)}
              sx={{
                color: "#000000",
                backgroundColor: "#FFFFFF",
                border: "1px solid #1C1C1C",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#E5E9EF",
                  color: "#000000",
                },
              }}
            >
              Edit
            </MDButton>
          ) : (
            <>
              <MDButton
                variant="contained"
                onClick={() => handleCancel()}
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1C1C",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#E5E9EF",
                    color: "#000000",
                  },
                  marginRight: "5px",
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                onClick={handleSave}
                sx={{
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1C1C",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#F0F0F0",
                  },
                }}
                disabled={isUpdating || !activeEntityId}
                startIcon={isUpdating ? null : <SaveOutlinedIcon sx={{ mb: "1px" }} />}
              >
                {isUpdating ? "Saving..." : "Save"}
              </MDButton>
            </>
          )}
        </MDBox>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Business Name"
              value={formData.businessName}
              onChange={(e: any) => handleChange("businessName", e.target.value)}
              disabled={!isEditing}
              {...formFieldStyles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Street Address"
              value={formData.address}
              onChange={(e: any) => handleChange("address", e.target.value)}
              disabled={!isEditing}
              {...formFieldStyles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Business Website"
              value={formData.website}
              onChange={(e: any) => handleChange("website", e.target.value)}
              inputProps={{ type: "website" }}
              disabled
              {...formFieldStyles}
            />
          </Grid>

          {/* Right column container for Suburb, City, Country, and Zip Code */}
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              {/* Suburb and City next to one another */}
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Suburb"
                  value={formData.suburb}
                  onChange={(e: any) => handleChange("suburb", e.target.value)}
                  disabled={!isEditing}
                  {...formFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="City"
                  value={formData.city}
                  onChange={(e: any) => handleChange("city", e.target.value)}
                  disabled={!isEditing}
                  {...formFieldStyles}
                />
              </Grid>

              {/* Country and Zip Code next to one another */}
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Country"
                  value={formData.country}
                  onChange={(e: any) => handleChange("country", e.target.value)}
                  disabled={!isEditing}
                  {...formFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Zip Code"
                  value={formData.zip}
                  onChange={(e: any) => handleChange("zip", e.target.value)}
                  disabled={!isEditing}
                  {...formFieldStyles}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default BasicInfo;
