import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { getAdminKpiStats, AdminKpiStats } from "services/ventureApi";
import { setAdminKpiData } from "features/admin/adminSlice";

export const useAdminKpiData = () => {
  const dispatch = useDispatch();
  // const adminKpiData = useSelector((state: RootState) => state.admin.kpiStats);

  const { data, isLoading, error } = useQuery<AdminKpiStats>({
    queryKey: ["adminKpiData"],
    queryFn: getAdminKpiStats,
    staleTime: 30 * 60 * 1000, // 30 minutes
  });

  useEffect(() => {
    if (data) {
      dispatch(setAdminKpiData(data));
    }
  }, [data, dispatch]);

  return {
    adminKpiData: data,
    isAdminKpiLoading: isLoading,
    adminKpiError: error,
  };
};
