import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { setUserProfile, setLoading, setError } from "features/user/userSlice";
import { getUserProfile } from "services/ventureApi"; // assuming your service is set up as shown earlier

export const useUserProfile = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsFetching(true);
      dispatch(setLoading(true));

      try {
        const response = await getUserProfile();
        dispatch(setUserProfile(response)); // Update the state with the fetched user profile
      } catch (error) {
        dispatch(setUserProfile(null));
        dispatch(setError("Failed to fetch user profile"));
      } finally {
        setIsFetching(false);
        dispatch(setLoading(false));
      }
    };

    fetchUserProfile();
  }, [dispatch]);

  return { isFetching };
};
