import { useDispatch } from "react-redux";
import { deleteAdminUser } from "services/ventureApi";
import { setDeleteStatus, setDeleteMessage } from "features/admin/adminSlice";
import { useAdminUsersData } from "./useAdminUsersData";

export const useDeleteAdminUser = () => {
  const dispatch = useDispatch();
  const { refetch } = useAdminUsersData();

  const handleDeleteAdminUser = async (user_id: number, entity_id: number) => {
    try {
      dispatch(setDeleteStatus("loading"));
      const response = await deleteAdminUser({ user_id, entity_id });

      if (response.success) {
        dispatch(setDeleteStatus("success"));
        dispatch(setDeleteMessage("response.message"));
        refetch();
      } else {
        dispatch(setDeleteStatus("error"));
        dispatch(setDeleteMessage("Failed to delete user"));
      }
    } catch (error) {
      dispatch(setDeleteStatus("error"));
      dispatch(setDeleteMessage("Error occurred during deletion"));
      console.error("Error during delete:", error);
    }
  };

  return {
    handleDeleteAdminUser,
  };
};
