// src/hooks/admin/useEntitiesForAdminData.ts
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "app/store";
import { getEntitiesForAdmin } from "services/ventureApi";
import { setEntitiesForAdminData, setTotalEntityCount } from "features/admin/adminSlice";

export const useEntitiesForAdminData = (skip = 0, limit = 100) => {
  const dispatch = useDispatch();
  const { entitiesForAdmin, totalEntityCount } = useSelector((state: RootState) => state.admin);

  const {
    data: entityData,
    isLoading: isEntitiesLoading,
    error,
    refetch: refetchEntities,
  } = useQuery({
    queryKey: ["entitiesForAdmin", skip, limit],
    queryFn: () => getEntitiesForAdmin(skip, limit),
    staleTime: 30 * 60 * 1000, // 30 minutes
    refetchOnWindowFocus: false,
  });

  /**
   * entityData typically: {
   *   entities: AdminEntity[];
   *   total_count: number;
   * }
   */
  useEffect(() => {
    if (entityData) {
      dispatch(setEntitiesForAdminData(entityData.entities));
      dispatch(setTotalEntityCount(entityData.total_count));
    }
  }, [entityData, dispatch]);

  return {
    entities: entitiesForAdmin,
    totalCount: totalEntityCount,
    isEntitiesLoading,
    error,
    refetchEntities,
  };
};
