import React, { useId } from "react";
import { Box, Card, CardContent, Typography, Button, IconButton } from "@mui/material";
import { RootState } from "app/store";
import { useSelector } from "react-redux";

interface DeleteAdminModalProps {
  onClose: () => void;
  itemName: string; // Name of the file or folder to delete
  selectedUserId: number; // Name of the file or folder to delete
  confirmDelete: (userId: number, adminEntityId: number) => void;
}

const DeleteAdminModal: React.FC<DeleteAdminModalProps> = ({
  onClose,
  itemName,
  confirmDelete,
  selectedUserId,
}) => {
  const adminEntityId = useSelector((state: RootState) => state.admin.adminEntityId);

  return (
    <>
      {/* Backdrop for dimming background */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
          zIndex: 1000,
        }}
        onClick={onClose}
      />

      {/* Central Card */}
      <Card
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#FFFF",
          color: "white",
          width: "585px",
          height: "fit-content", // Increased height
          padding: "16px",
          zIndex: 1001,
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "8px",
        }}
      >
        {/* Card Content */}
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textAlign: "center",
              marginBottom: "16px",
              fontWeight: "700",
              color: "#1C1C1C",
            }}
          >
            Are you sure you want to remove <strong>{itemName}</strong> as a Platform Administrator?
          </Typography>
          <Box display="flex" justifyContent="center" gap="16px">
            {/* CANCEL Button */}
            <Button
              variant="contained"
              onClick={onClose}
              sx={{
                backgroundColor: "#E5E9EF",
                color: "#344767",
                textTransform: "uppercase",
                fontWeight: "700",
                padding: "13px 26px",
                border: "none", // Remove the border entirely
                borderRadius: "8px",
                outline: "none", // Remove the outline
                boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)", // Remove any box-shadow (focus state or hover)
                ":hover": {
                  backgroundColor: "#E5E9EF",
                  border: "none", // Ensure no border appears on hover
                  boxShadow: "none", // Remove any box-shadow during hover
                },
              }}
            >
              Cancel
            </Button>

            {/* YES, DELETE or MOVE Button */}
            <Button
              variant="contained"
              // onClick={onDelete}
              sx={{
                backgroundColor: "#202020", // White background for delete, grey for cancel
                color: "#FFFFFF", // Delete text in dark color, cancel text in white
                textTransform: "uppercase",
                fontWeight: "700",
                padding: "13px 26px",
                border: "none", // Remove the border entirely
                borderRadius: "8px",
                outline: "none", // Remove the outline
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)", // Remove any box-shadow (focus state or hover)
                ":hover": {
                  backgroundColor: "#202020", // Light grey for delete hover, darker grey for cancel hover
                  border: "none", // Ensure no border appears on hover
                  boxShadow: "none", // Remove any box-shadow during hover
                },
              }}
              onClick={() => {
                confirmDelete(selectedUserId, adminEntityId);
                onClose();
              }}
            >
              Yes
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DeleteAdminModal;
