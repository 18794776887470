import React, { useState } from "react";
import { IconButton, Avatar, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";

import { AdminEntity } from "services/ventureApi";
import entityPlaceholder from "assets/images/reisiger/BierMier.webp";
//pdf
import { pdf } from "@react-pdf/renderer";
import EntityPDFDocument from ".//pdf/EntityPDFDocument";

import { getEntityById } from "services/ventureApi";
import { DetailedEntityResponse } from "types/adminTypes";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";

interface EntityDetailPanelProps {
  entity: AdminEntity;
  onClose: () => void;
}

const EntityDetailPanel: React.FC<EntityDetailPanelProps> = ({ entity, onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  //const dispatch = useDispatch();
  const { profile } = useSelector((state: RootState) => state.user);
  const fullName = `${profile?.first_name} ${profile?.last_name}` || "Username not found";

  // Example delete handler
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      // call deleteEntityForAdmin service
      // on success:
      //   setIsDeleting(false);
      //   onClose();
    } catch (err) {
      console.error("Error deleting entity:", err);
      setIsDeleting(false);
      // show error
    }
  };
  const handleDownloadPDF = async () => {
    setIsDownloading(true);

    try {
      const fullData: DetailedEntityResponse = await getEntityById(entity.entity_id);
      console.log("fullData", fullData);
      const blob = await pdf(
        <EntityPDFDocument data={fullData} entity={entity} userName={fullName} />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      const linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = `entity_${entity.entity_id}.pdf`;
      document.body.appendChild(linkEl);
      linkEl.click();
      document.body.removeChild(linkEl);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };
  // Example PDF download handler
  /*
  const handleDownloadPDF = () => {
    // implement your PDF download logic
    alert("Download PDF clicked");
  };

  */

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  return (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
        p: 3,
        boxShadow: "md",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        position: "relative",
      }}
    >
      {/* Close Button */}
      <IconButton onClick={onClose} sx={{ alignSelf: "flex-end" }}>
        <CloseIcon />
      </IconButton>

      {/* Entity Thumbnail */}
      {entity.users && entity.users.length > 0 ? (
        entity.users.map((user, idx) => (
          <Avatar
            key={`user-avatar-${idx}`} // Unique key prop
            src={user.user_icon || entityPlaceholder}
            sx={{
              width: 80,
              height: 80,
              mb: 2,
              border: "solid 1px #BEBEBE",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            }}
            variant="rounded"
          />
        ))
      ) : (
        <Avatar src={entityPlaceholder} sx={{ width: 80, height: 80, mb: 2 }} variant="rounded" />
      )}
      {/* <Avatar src={entityPlaceholder} sx={{ width: 80, height: 80, mb: 2 }} variant="rounded" /> */}

      {/* Entity Info */}
      <DetailRow label="Entity ID" value={String(entity.entity_id)} />
      <DetailRow label="Entity Name" value={entity.entity_name} />
      <DetailRow label="Entity Type" value={entity.entity_type} />
      <DetailRow label="Date Created" value={formatDate(entity.date_added)} />
      <DetailRow label="Registration #" value={entity.business_registration_number ?? "N/A"} />
      <DetailRow label="Website" value={entity.website ?? "N/A"} />
      <DetailRow label="Location" value={entity.location ?? "N/A"} />
      <DetailRow label="Tax Ref #" value={entity.tax_reference_number ?? "N/A"} />

      {/* Buttons row */}
      <MDBox display="flex" gap={2} mt={2}>
        <MDButton
          variant="outlined"
          color="error"
          startIcon={isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />}
          disabled={isDeleting}
          onClick={handleDelete}
          sx={{ textTransform: "none" }}
        >
          {isDeleting ? "Deleting..." : "Delete Entity"}
        </MDButton>

        <MDButton
          variant="outlined"
          color="info"
          startIcon={<FileDownloadIcon />}
          onClick={handleDownloadPDF}
          sx={{ textTransform: "none" }}
        >
          {isDownloading ? "Generating..." : "Download PDF"}
        </MDButton>
      </MDBox>

      {/* TEAM MEMBERS Section */}
      <MDBox mt={2}>
        <MDTypography variant="h6" fontWeight="bold">
          Team Members
        </MDTypography>
        {entity.users && entity.users.length > 0 ? (
          entity.users.map((user, idx) => <TeamMemberItem key={idx} user={user} />)
        ) : (
          <MDTypography variant="body2">No team members.</MDTypography>
        )}
      </MDBox>

      {/* LINKED ENTITIES Section */}
      <MDBox mt={2}>
        <MDTypography variant="h6" fontWeight="bold">
          Linked Entities
        </MDTypography>
        {entity.linked_entities && entity.linked_entities.length > 0 ? (
          entity.linked_entities.map((linked) => (
            <LinkedEntityItem key={linked.entity_id} linked={linked} />
          ))
        ) : (
          <MDTypography variant="body2">No linked entities.</MDTypography>
        )}

        {/* "Add New Connection" button */}
        <MDButton variant="contained" color="success" sx={{ mt: 2, textTransform: "none" }}>
          + ADD NEW CONNECTION
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default EntityDetailPanel;

// Simple row for label/value
const DetailRow: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <MDBox display="flex" justifyContent="space-between" alignItems="center" px={1}>
    <MDTypography variant="body2" fontWeight="bold">
      {label}:
    </MDTypography>
    <MDTypography variant="body2" ml={2}>
      {value}
    </MDTypography>
  </MDBox>
);

// Team Member item
interface TeamMember {
  first_name: string;
  last_name: string;
  user_role?: string;
  user_team_role?: string;
}
const TeamMemberItem: React.FC<{ user: TeamMember }> = ({ user }) => (
  <MDBox
    display="flex"
    alignItems="center"
    gap={2}
    py={1}
    sx={{
      borderBottom: "1px solid #eee",
    }}
  >
    <Avatar src={entityPlaceholder} sx={{ width: 40, height: 40 }} />
    <MDBox>
      <MDTypography variant="body2" fontWeight="bold">
        {user.first_name} {user.last_name}
      </MDTypography>
      <MDTypography variant="caption" display="block">
        User Role: {user.user_role || "N/A"}
      </MDTypography>
      <MDTypography variant="caption" display="block">
        Team Role: {user.user_team_role || "N/A"}
      </MDTypography>
    </MDBox>
  </MDBox>
);

// Linked Entity item
interface LinkedEntity {
  entity_id: number;
  entity_name: string;
  entity_type: string;
  date_added: string;
}
const LinkedEntityItem: React.FC<{ linked: LinkedEntity }> = ({ linked }) => (
  <MDBox
    display="flex"
    alignItems="center"
    gap={2}
    py={1}
    sx={{
      borderBottom: "1px solid #eee",
    }}
  >
    <Avatar src={entityPlaceholder} sx={{ width: 40, height: 40 }} />
    <MDBox flex={1}>
      <MDTypography variant="body2" fontWeight="bold">
        {linked.entity_name}
      </MDTypography>
      <MDTypography variant="caption" display="block">
        Entity Type: {linked.entity_type}
      </MDTypography>
      <MDTypography variant="caption" display="block">
        Date Added: {linked.date_added}
      </MDTypography>
    </MDBox>

    <MDTypography
      variant="body2"
      fontWeight="bold"
      color="error"
      sx={{ cursor: "pointer" }}
      onClick={() => alert(`Delink ${linked.entity_name} here`)}
    >
      DELINK
    </MDTypography>
  </MDBox>
);
