import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import ComplexProjectCard from "components/refined/Cards/ProjectCards/ComplexProjectCard";
import DashboardLayout from "components/refined/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/refined/Navbars/DashboardNavbar";
import Header from "./components/Header";
import Footer from "components/refined/Footer";
import BusinessPortfolioCard from "./components/BusinessPortifolioCard";
import avatarImage from "assets/avatar.jpeg"; // Placeholder for the avatar images + business img
import blank_profile from "assets/blank_profile.png";
import logo_placeholder from "assets/logo_placeholder.png";
import AddBusinessModal from "./components/AddBusinessModal";
import { getBusinesses, favouriteEntity, getBusinessProfile } from "services/ventureApi";
import { useAuth } from "context/AuthContext";
import { Business } from "types/businessTypes";
import AboutModal from "./components/AboutModal";

//moc data

const MyPortfolio: React.FC = () => {
  // Sample data for startups
  const [openAddModal, setOpenAddModal] = useState(false);
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [linkedBusinesses, setLinkedBusinesses] = useState<Business[]>([]);
  const [invitedBusinesses, setInvitedBusinesses] = useState<Business[]>([]);
  const [favoritedBusinesses, setFavoritedBusinesses] = useState<Business[]>([]); // New state
  const [favoriteIds, setFavoriteIds] = useState<Set<number>>(new Set());
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const { loginResponse } = useAuth();
  const entityId = loginResponse?.entity?.entity_id;
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);
  const [businessProfile, setBusinessProfile] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);

  const handleAvatarClick = async (business: Business) => {
    if (!entityId) {
      console.log("no requestor id/entityId");
      return;
    }

    try {
      setLoadingProfile(true);
      const profile = await getBusinessProfile(entityId, business.entity_id);
      setBusinessProfile(profile);
      setSelectedBusiness(business);
      setIsModalOpen(true);
    } catch (error: any) {
      console.error("Error fetching business profile:", error);
    } finally {
      setLoadingProfile(false);
    }
  };

  const handleCloseAboutModal = () => {
    setIsModalOpen(false);
    setSelectedBusiness(null);
    setBusinessProfile(null);
  };

  const handleOpenModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };
  /*
  const handleFavoriteToggle = (favorited: boolean) => {
    console.log(favorited ? "Marked as favorite!" : "Removed from favorites");
    // Handle API call or state update here
  };
  */

  const handleFavoriteToggle = async (business: Business) => {
    const isCurrentlyFavorited = favoriteIds.has(business.entity_id);
    const action = isCurrentlyFavorited ? "remove" : "add";

    try {
      setActionLoading(true);
      await favouriteEntity({ entity_id: business.entity_id, action });
      setFavoriteIds((prev) => {
        const newSet = new Set(prev);
        if (action === "add") {
          newSet.add(business.entity_id);
        } else {
          newSet.delete(business.entity_id);
        }
        return newSet;
      });

      setFavoritedBusinesses((prev) => {
        if (action === "add") {
          return [...prev, business];
        } else {
          return prev.filter((b) => b.entity_id !== business.entity_id);
        }
      });

      console.log(action === "add" ? "Marked as favorite!" : "Removed from favorites");
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
      // notif here..?
    } finally {
      setActionLoading(false);
    }
  };

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        if (entityId) {
          const response = await getBusinesses(entityId);
          console.log("businesses: ", response);
          const allBusinesses = response.all_businesses || [];
          const linkedBusinesses = response.linked_businesses || [];
          const invitedBusinesses = response.invited_businesses || [];
          const favoriteBusinesses = response.favourited_entities || [];
          console.log("All Businesses:", allBusinesses);
          console.log("Linked Businesses:", linkedBusinesses);
          console.log("Invited Businesses:", invitedBusinesses);
          console.log("Favorited Businesses:", favoriteBusinesses);
          const linkedIds = new Set(linkedBusinesses.map((b: Business) => b.entity_id));
          const filteredAllBusinesses = allBusinesses.filter(
            (b: Business) => !linkedIds.has(b.entity_id)
          );

          setInvitedBusinesses(invitedBusinesses);
          setBusinesses(filteredAllBusinesses);
          setLinkedBusinesses(linkedBusinesses);
          setFavoritedBusinesses(favoriteBusinesses);
          //setBusinesses(response.all_businesses); // Set businesses in state
          const favIds = new Set<number>();
          favoriteBusinesses.forEach((b: Business) => favIds.add(b.entity_id));
          setFavoriteIds(favIds);
        }
      } catch (error) {
        console.error("Error fetching businesses:", error);
      } finally {
        //setLoading(false);
      }
    };

    fetchBusinesses();
  }, [entityId]);
  /*
  const handleInviteBusiness = (invitedBusiness: Business) => {
    setInvitedBusinesses([...invitedBusinesses, invitedBusiness]);
    setBusinesses(businesses.filter((b) => b.entity_name !== invitedBusiness.entity_name));
  };
  */

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header />
      <MDBox mb={2} />

      <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <MDTypography variant="h5" fontWeight="medium">
          Startups
        </MDTypography>
        <MDButton variant="gradient" color="primary" sx={{ ml: "auto" }} onClick={handleOpenModal}>
          + ADD NEW
        </MDButton>
      </MDBox>

      {/* Accepted Startups Section */}
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium" mb={5}>
          Accepted Startups
        </MDTypography>
        <Grid container spacing={2}>
          {linkedBusinesses.map((startup, index) => (
            <Grid item xs={12} md={6} lg={4} key={`accepted-${index}`}>
              <BusinessPortfolioCard
                business={startup}
                canBeFavorited={true}
                isFavorited={favoriteIds.has(startup.entity_id)}
                onFavoriteToggle={() => handleFavoriteToggle(startup)}
                loading={actionLoading}
                onAvatarClick={handleAvatarClick}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>

      {/* Pending Invitation Section */}
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium" mb={5}>
          Pending Invitation Acceptance
        </MDTypography>
        <Grid container spacing={2}>
          {invitedBusinesses.map((startup, index) => (
            <Grid item xs={12} md={6} lg={4} key={`pending-${index}`}>
              <BusinessPortfolioCard business={startup} canBeFavorited={false} />
            </Grid>
          ))}
        </Grid>
      </MDBox>
      <AddBusinessModal
        open={openAddModal}
        handleClose={handleCloseModal}
        startups={businesses}
        //handleInviteBusiness={handleInviteBusiness}
        requestorId={loginResponse.entity.entity_id}
      />
      <AboutModal
        open={isModalOpen}
        handleClose={handleCloseAboutModal}
        data={businessProfile}
        loading={loadingProfile}
      />
      <Footer />
    </DashboardLayout>
  );
};

export default MyPortfolio;
