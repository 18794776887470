import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import MDAvatar from "components/generic/MDAvatar";
import EditProfilePic from "assets/images/startup-profile/edit-dp.png";
import { useAuth } from "context/AuthContext";
import UploadComponent from "components/shared/questionnaire/UploadComponent";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";

import { retrieveMedia } from "services/ventureApi";

const Header = () => {
  const [visible, setVisible] = useState<boolean>(true);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [entityImage, setEntityImage] = useState<string | null>(null);
  const { loginResponse } = useAuth();

  const businessName = loginResponse?.entity.entity_name || "Businsess Name";
  //const teamRole = loginResponse?.entity?.additional_info?.profile?.teamRole || "Role";

  const handleSetVisible = () => setVisible(!visible);
  const handleOpenUploadModal = () => {
    setUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
  };

  const handleImageUpload = (newImageBase64: string) => {
    setEntityImage(newImageBase64); // Update profileImage state with the uploaded image base64 string
    setUploadModalOpen(false); // Close the modal after uploading
  };

  const fetchEntityImage = async () => {
    try {
      // Use the entityId to fetch the media
      const mediaResponse = await retrieveMedia("entity_logo");
      console.log("mediaResponse", mediaResponse);
      if (mediaResponse.presignedUrl) {
        setEntityImage(mediaResponse.presignedUrl);
      }
    } catch (error) {
      console.error("Error fetching entity image:", error);
    }
  };

  useEffect(() => {
    fetchEntityImage();
  }, [entityImage]);
  // useEffect(() => {
  //   const storedImage = localStorage.getItem("profileImage"); // Fetch the image from local storage
  //   if (storedImage) {
  //     setProfileImage(storedImage); // Set it as the profile image if found
  //   }
  // }, []);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center" sx={{ height: "112px" }}>
          <Grid item>
            <MDBox display={"flex"} alignItems={"end"}>
              <MDAvatar
                src={entityImage || EditProfilePic} // Display uploaded image if exists, else default pic
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
              <MDBox
                sx={{
                  backgroundColor: "#88966E",
                  borderRadius: "8px",
                  width: "24px",
                  height: "24px",
                  marginLeft: "-12px",
                  marginBottom: "-5px",
                  zIndex: "1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
                onClick={handleOpenUploadModal} // Open modal on avatar clic
              >
                <EditIcon sx={{ color: "#FFFFFF", fontSize: "small", padding: "2px" }} />
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography
                sx={{ color: "#344767", fontWeight: "700", fontFamily: "Roboto", fontSize: "20px" }}
              >
                {businessName}
              </MDTypography>
            </MDBox>
          </Grid>
          {/*
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              
              <MDTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </MDBox>
            </MDBox>
          </Grid>
          */}
        </Grid>
      </MDBox>
      <Modal
        open={uploadModalOpen}
        onClose={handleCloseUploadModal} // Close the modal when clicking outside
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          onClick={handleCloseUploadModal} // Close modal on background click
        >
          <MDBox
            onClick={(e) => e.stopPropagation()} // Prevent background click from closing modal when clicking on the upload component
            sx={{ p: 3, bgcolor: "white", borderRadius: 2 }}
          >
            <UploadComponent
              onClose={handleCloseUploadModal}
              btndata={(value: boolean) => handleImageUpload(value ? "path_to_new_image" : "")}
              purpose="user_profile"
              // Replace with uploaded image URL
            />
          </MDBox>
        </MDBox>
      </Modal>
    </Card>
  );
};

export default Header;
