import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Typography,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";

import ViewListIcon from "@mui/icons-material/ViewList";
import DeleteIcon from "@mui/icons-material/Delete";
import GridViewIcon from "@mui/icons-material/GridView";

import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";

import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import entityPlaceholder from "assets/images/reisiger/BierMier.webp";

import { UserForAdmin, AdminKpiStats } from "services/ventureApi";

type UserKeys = keyof UserForAdmin;

const TableHeaderCell = styled(TableCell)(() => ({
  //fontWeight: "bold",
  //display: "flex",
  //alignItems: "center",
  color: "#1C1C1C",
  fontSize: "12px",
  fontFamily: "Titillium Web,sans-serif",
  fontWeight: 700,
}));

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateAdded: string;
  entities: { name: string }[];
}

interface UsersAdminTableProps {
  users?: UserForAdmin[];
  isLoading?: boolean;
  onViewUser: (user: UserForAdmin) => void;
}
const UsersAdminTable: React.FC<UsersAdminTableProps> = ({ users, onViewUser, isLoading }) => {
  const [sortConfig, setSortConfig] = useState<{ key?: UserKeys; direction: "asc" | "desc" }>({
    key: undefined,
    direction: "asc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users || []);

  const displayedUsers = useMemo(() => {
    let sortedFilteredUsers = [...users];

    // Apply sorting if a column is selected
    if (sortConfig.key) {
      sortedFilteredUsers.sort((a, b) => {
        const aValue = a[sortConfig.key!] ?? "";
        const bValue = b[sortConfig.key!] ?? "";

        return sortConfig.direction === "asc"
          ? String(aValue).localeCompare(String(bValue))
          : String(bValue).localeCompare(String(aValue));
      });
    }
    // Apply filtering based on searchQuery
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      sortedFilteredUsers = sortedFilteredUsers.filter(
        (user) =>
          user.first_name.toLowerCase().includes(query) ||
          user.last_name.toLowerCase().includes(query) ||
          user.email.toLowerCase().includes(query)
      );
    }

    return sortedFilteredUsers;
  }, [users, searchQuery, sortConfig]);

  const handleSort = (key: UserKeys) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue = (a as any)[sortConfig.key];
    const bValue = (b as any)[sortConfig.key];
    return sortConfig.direction === "asc" ? (aValue < bValue ? -1 : 1) : aValue > bValue ? -1 : 1;
  });

  const entitiesIconsMax = 3;

  const formatDate = (isoDate: string) => {
    if (!isoDate) return "N/A";
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-GB", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };

  useEffect(() => {
    if (users) {
      console.log("Table received new data:", users);
      setFilteredUsers(users);
    }
  }, [users]);

  return (
    <>
      <MDBox
        sx={{
          // position: "fixed",
          width: "100%",
          //width: "99%",
          borderRadius: "12px",
          backgroundColor: "#FFFFFF",
          p: 3,
        }}
      >
        <MDTypography
          variant="h5"
          fontWeight={700}
          sx={{ mb: 2, color: "#4C4E52" }}
          /*
          sx={{
            paddingTop: "10px",
            color: "#4C4E52",
            marginLeft: 4,
            fontWeight: 700,
            fontSize: "20px",
          }}
          */
        >
          Users
        </MDTypography>

        <MDBox sx={{ px: 4, width: "100%", mb: "24px" }} display={"flex"} alignItems="center">
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            /*
            onChange={(e) => {
              const query = e.target.value.toLowerCase();
              setFilteredUsers(
                users.filter(
                  (user) =>
                    user.first_name.toLowerCase().includes(query) ||
                    user.last_name.toLowerCase().includes(query) ||
                    user.email.toLowerCase().includes(query)
                )
              );
                  }}
                  */
            fullWidth
            margin="normal"
            InputProps={{
              sx: {
                color: "#1C1C1C !important",
                "& .MuiInputBase-input": {
                  color: "#1C1C1C !important",
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#1C1C1C !important",
                  opacity: 1,
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#1C1C1C !important",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#7B809A !important",
                },
                "&:hover fieldset": {
                  borderColor: "#7B809A !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#7B809A !important",
                },
              },
            }}
            placeholder="Search here"
          />
        </MDBox>

        {isLoading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "100%",
              overflowY: "auto",
              overflowX: "auto",
              "&::-webkit-scrollbar": { width: "6px", height: "6px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            }}
          >
            <Table>
              <TableHead
                sx={{
                  display: "table-header-group",
                }}
              >
                <TableRow>
                  {[
                    "User ID",
                    "User Name",
                    "User Surname",
                    "Email",
                    "Date Added",
                    "Entities",
                    "Actions",
                  ].map((column) => (
                    <TableHeaderCell
                      key={column}
                      //onClick={() => handleSort(column.replace(/\s+/g, "").toLowerCase())}
                      onClick={() => handleSort(column as UserKeys)}
                      sx={{ width: "full", textAlign: "left" }}
                    >
                      {column.replace("_", " ")}
                      <UnfoldMoreIcon
                        fontSize="small"
                        sx={{
                          //marginLeft: "30%",
                          marginBottom: "-2px",
                          color: "#1C1C1C",
                          //width: "20px",
                          height: "12px",
                          cursor: "pointer",
                          verticalAlign: "middle",
                        }}
                      />
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedUsers.map((user) => (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.user_id}</TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatDate(user.date_added)}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {user.entities && user.entities.length > entitiesIconsMax ? (
                          <>
                            {user.entities
                              .slice(0, entitiesIconsMax)
                              .map((entity: any, index: any) => (
                                <Avatar
                                  key={index}
                                  src={entityPlaceholder}
                                  alt={entity.entity_name}
                                  sx={{
                                    width: 32,
                                    height: 32,
                                    // marginLeft: index === 0 ? 0 : -12,
                                    border: "2px solid white",
                                  }}
                                />
                              ))}
                            <Avatar
                              sx={{ width: 32, height: 32, marginLeft: 0, bgcolor: "grey.500" }}
                            >
                              <MDTypography
                                color="#fff"
                                variant="subtitle"
                                fontWeight="medium"
                                fontSize="12px"
                                alignSelf="center"
                              >
                                {" "}
                                +{user.entities.length - entitiesIconsMax}
                              </MDTypography>
                            </Avatar>
                          </>
                        ) : (
                          user.entities.map((entity: any, index: any) => (
                            <Avatar
                              key={index}
                              src={entity.entity_icon || entityPlaceholder}
                              alt={entity.entity_name}
                              sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                border: `${borderWidth[2]} solid ${white.main}`,
                                cursor: "pointer",
                                position: "relative",
                                width: 32,
                                height: 32,
                                ml: -1.25,
                                "&:hover, &:focus": {
                                  zIndex: "10",
                                },
                              })}
                            />
                          ))
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => onViewUser(user)}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </MDBox>
    </>
  );
};

// Dummy data example
const dummyUsers = [
  {
    user_id: 1,
    first_name: "John",
    last_name: "Doe",
    email: "john.doe@example.com",
    date_added: "2025-01-10",
    mobile: "0124356789",
    location: "US",
    entity_deletion_count: 1,
    entity_count: 1,
    entities: [
      {
        entity_name: "name",
        role_name: "role",
        user_count: 1,
      },
    ],
  },
  {
    user_id: 2,
    first_name: "Jane",
    last_name: "Smith",
    email: "jane.smith@example.com",
    date_added: "2025-01-11",
    //entities: Array(3).fill({ name: "Entity" }),
    mobile: "0124356789",
    location: "US",
    entity_deletion_count: 1,
    entity_count: 1,
    entities: [
      {
        entity_name: "name",
        role_name: "role",
        user_count: 1,
      },
    ],
  },
];

export default UsersAdminTable;
