import { configureStore } from "@reduxjs/toolkit";
//import { persistReducer, persistStore } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

import userReducer from "../features/user/userSlice";
import adminReducer from "../features/admin/adminSlice";
import updateUserProfileReducer from "../features/user/updateProfileSlice";
import entityReducer from "../features/entity/entitySlice";
/*
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Only persist 'auth'
};
*/
const rootReducer = combineReducers({
  // auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  updateUserProfile: updateUserProfileReducer,
  entity: entityReducer,
});

//const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer, // vs persistedReducer
  devTools: process.env.NODE_ENV !== "production",
});

//export const persistor = persistStore(store);
/*
export const store = configureStore({
  reducer: {
    auth: authReducer, // Add auth slice
  },
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools
});
*/

// Infer types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
